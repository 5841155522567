import { getAppUrl } from '@/services/radar.service'

const state = () => {
  return {
    appUrl: '',
  }
}

const SET_APP_URL = 'SET_APP_URL'

const mutations = {
  [SET_APP_URL]: (state, appUrl) => {
    state.appUrl = appUrl
  },
}

const actions = {
  getAppUrl: async ({ commit, rootState }) => {
    const appUrl = await getAppUrl(rootState.user.currentClient.id)
    commit(SET_APP_URL, appUrl)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
