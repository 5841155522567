import Vue from 'vue'

const SET_CURRENT_MENU = 'SET_CURRENT_MENU'
const SET_IS_NOTIFICATIONS_OPEN = 'SET_IS_NOTIFICATIONS_OPEN'
const SET_IS_ACCOUNT_OPEN = 'SET_IS_ACCOUNT_OPEN'
const SET_IS_BRANDS_OPEN = 'SET_IS_BRANDS_OPEN'
const SET_CURRENT_CONTEXT = 'SET_CURRENT_CONTEXT'
const SET_DARK_THEME = 'SET_DARK_THEME'

const state = () => {
  return {
    darkTheme: !!(localStorage.getItem('dark_theme') * 1) || false,
    currentMenu: '',
    isNotificationsOpen: false,
    isAccountOpen: false,
    isBrandsOpen: false,
    currentContext: '',
  }
}

const mutations = {
  [SET_CURRENT_MENU]: (state, menu) => {
    state.currentMenu = menu
  },
  [SET_IS_NOTIFICATIONS_OPEN]: (state, boolean) => {
    state.isNotificationsOpen = boolean
  },
  [SET_IS_ACCOUNT_OPEN]: (state, boolean) => {
    state.isAccountOpen = boolean
  },
  [SET_IS_BRANDS_OPEN]: (state, boolean) => {
    state.isBrandsOpen = boolean
  },
  [SET_CURRENT_CONTEXT]: (state, context) => {
    state.currentContext = context
  },
  [SET_DARK_THEME]: (state, boolean) => {
    state.darkTheme = boolean
    localStorage.setItem('dark_theme', +boolean)
  },
}

const actions = {
  toggleDarkTheme: ({ commit, state }) => {
    if (state.darkTheme === true) {
      commit(SET_DARK_THEME, false)
    } else {
      commit(SET_DARK_THEME, true)
    }
  },
  toggleMenu: ({ commit, dispatch, state }, menu) => {
    dispatch('closeEverything', { menu: false, notifications: true, account: true, brands: true })
    if (state.currentMenu === menu || menu === '') {
      commit(SET_CURRENT_MENU, '')
    } else {
      commit(SET_CURRENT_MENU, menu)
    }
  },
  toggleNotifications: ({ commit, dispatch, state }) => {
    dispatch('closeEverything', { menu: true, notifications: false, account: true, brands: true })
    if (state.isNotificationsOpen === true) {
      dispatch('user/sendNotifications', true, { root: true })
      commit(SET_IS_NOTIFICATIONS_OPEN, false)
    } else {
      commit(SET_IS_NOTIFICATIONS_OPEN, true)
    }
  },
  toggleAccount: ({ commit, dispatch, state }) => {
    dispatch('closeEverything', { menu: true, notifications: true, account: false, brands: true })
    if (state.isAccountOpen === true) {
      commit(SET_IS_ACCOUNT_OPEN, false)
    } else {
      commit(SET_IS_ACCOUNT_OPEN, true)
    }
  },
  toggleBrands: ({ commit, dispatch, state }, element) => {
    dispatch('closeEverything', { menu: true, notifications: true, account: true, brands: false })
    if (state.isBrandsOpen === true) {
      commit(SET_IS_BRANDS_OPEN, false)
    } else {
      commit(SET_IS_BRANDS_OPEN, true)
      Vue.prototype.$nextTick(() => {
        document.getElementById(element).getElementsByTagName('input')[0].focus()
      })
    }
  },
  closeEverything: ({ commit, dispatch, state }, { menu, notifications, account, brands }) => {
    if (menu) {
      commit(SET_CURRENT_MENU, '')
    }
    if (notifications && state.isNotificationsOpen === true) {
      dispatch('user/sendNotifications', true, { root: true })
      commit(SET_IS_NOTIFICATIONS_OPEN, false)
    }
    if (account) {
      commit(SET_IS_ACCOUNT_OPEN, false)
    }
    if (brands) {
      commit(SET_IS_BRANDS_OPEN, false)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
