<template>
  <div id="backoffice" :style="productColor">
    <router-view :key="currentClientId" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { productColor } from '@/config/productColor.config'

export default {
  name: 'App',
  mounted() {
    if (this.darkTheme) {
      document.documentElement.setAttribute('data-theme', 'dark')
    } else {
      document.documentElement.removeAttribute('data-theme')
    }
  },
  computed: {
    ...mapState({
      darkTheme: state => state.backoffice.darkTheme,
      currentClientId: state => state.user.currentClient?.id,
    }),
    productColor() {
      return {
        '--product-color': productColor[this.$route.meta.productLine],
      }
    },
  },
}
</script>
