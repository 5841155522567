import { sendGetRequest, sendPostRequest, sendPutRequest, sendDeleteRequest } from '@/services/api.service'
import { API_GMB } from '@/config/api.config'

export const listQuestionsAnswers = ({ clientId, account, location, page, size }) => {
  const params = {
    clientId,
    account,
  }
  if (location != null) params.location = location
  if (page != null) params.page = page
  if (size != null) params.size = size
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.QUESTIONS_ANSWERS}`, {
    params,
  })
}

export const getQuestionsAnswersDetail = ({ clientId, account, location, id, page, size }) => {
  const params = {
    clientId,
    account,
    location,
    id,
  }
  if (page != null) params.page = page
  if (size != null) params.size = size
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.QUESTIONS_ANSWERS_ID}`, { params })
}

export const createQuestion = ({ clientId, account, location, text }) => {
  return sendPutRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.QUESTIONS_ANSWERS_ID}`,
    { text },
    {
      params: {
        clientId,
        account,
        location,
      },
    }
  )
}

export const updateQuestion = ({ clientId, account, location, text }) => {
  return sendPostRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.QUESTIONS_ANSWERS_ID}`,
    { text },
    {
      params: {
        clientId,
        account,
        location,
      },
    }
  )
}

export const deleteQuestion = ({ clientId, account, location }) => {
  return sendDeleteRequest(`${API_GMB.BASE_API_URL}${API_GMB.QUESTIONS_ANSWERS_ID}`, {
    params: {
      clientId,
      account,
      location,
    },
  })
}

export const createAnswer = ({ clientId, account, location, id, text }) => {
  return sendPutRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.QUESTIONS_ANSWERS_ID_ANSWER_ID}`,
    { text },
    {
      params: {
        clientId,
        account,
        location,
        id,
      },
    }
  )
}

export const updateAnswer = ({ clientId, account, location, id, text }) => {
  return sendPostRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.QUESTIONS_ANSWERS_ID_ANSWER_ID}`,
    { text },
    {
      params: {
        clientId,
        account,
        location,
        id,
      },
    }
  )
}

export const deleteAnswer = ({ clientId, account, location, id }) => {
  return sendDeleteRequest(`${API_GMB.BASE_API_URL}${API_GMB.QUESTIONS_ANSWERS_ID_ANSWER_ID}`, {
    params: {
      clientId,
      account,
      location,
      id,
    },
  })
}
