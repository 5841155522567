const DEFAULT_API_URL = '/api'

export const API_USER = {
  BASE_API_URL: `${DEFAULT_API_URL}/user/`,
  LOGIN: 'login.php',
  LOGOUT: 'logout.php',
  NAV: 'nav.php',
  NOTIFICATIONS: 'notifications.php',
  FORGET_PASSWORD: 'forget_password.php',
  PASSWORD: 'password.php',
  UPDATE_SETTINGS: 'update_settings.php',
  UPDATE_PASSWORD: 'update_password.php',
  GATEWAY_CHECK: 'gateway_check.php',
}

export const API_LPE = {
  BASE_API_URL: `${DEFAULT_API_URL}/lpe/`,
  LEADS: 'lead.php',
  LEAD: 'lead_id.php',
  LEAD_HISTORY: 'lead-history.php',
  LEAD_DASHBOARD_GRAPH: 'lead-dashboard-history.php',
  LEAD_DASHBOARD_OVERVIEW: 'lead-dashboard-participation.php',
  LEAD_DASHBOARD_SUMMARY: 'lead-dashboard-summary.php',
  LEAD_DASHBOARD_NETWORK: 'lead-dashboard-top.php',
  LEAD_DASHBOARD_PRODUCT: 'lead-dashboard-bottom.php',
  LOCATIONS: 'location.php',
  LOCATION: 'location_id_localized.php',
  LOCATION_OPENING_HOURS: 'location-opening-hours.php',
  LOCATION_OPENING_HOUR: 'location-opening-hours_id.php',
  LOCATION_EXCEPTIONAL_OPENING_HOURS: 'location-exceptional-opening-hours.php',
  LOCATION_EXCEPTIONAL_OPENING_HOUR: 'location-exceptional-opening-hours_id.php',
  LOCATION_URLS: 'location-url.php',
  LOCATION_URL: 'location-url_id.php',
  FRONT_OFFICES: 'front-office.php',
  FRONT_OFFICE: 'front-office_id_localized.php',
  FEATURE_LIST: 'feature-list.php',
  CLIENTS: 'client.php',
  CLIENT: 'client_id_localized.php',
  LOCALES: 'locale.php',
  CLIENT_FEATURES_LIST: 'feature-list.php',
  CLIENT_FEATURE_LIST: 'feature-list_id.php',
  USER_EXPRESS: 'user-express.php',
  USER_EXPRESS_ID: 'user-express_id.php',
  USER_EXPRESS_CAMPAIGN: 'user-express-campaign.php',
  USER_EXPRESS_CAMPAIGN_ID: 'user-express-campaign-id.php',
  USER_EXPRESS_CAMPAIGN_SUBMIT: 'user-express-campaign_submit.php',
  USER_EXPRESS_CAMPAIGN_HISTORY: 'user-express-campaign-history.php',
  TMP_LOCATION_OPENING_HOURS: 'tmp-location-opening-hours.php',
  TMP_LOCATION_EXCEPTIONAL_OPENING_HOURS: 'tmp-location-exceptional-opening-hours.php',
  NETLIFY_GEO_IP: '/.netlify/functions/geoip',
}

export const API_TOOLS = {
  BASE_API_URL: `${DEFAULT_API_URL}/tools/`,
  UPLOAD_MEDIA: 'upload_media.php',
  CLONE_MEDIA: 'clone_media.php',
  REMOVE_MEDIA: 'remove_media.php',
  GEOCODE: 'geocode.php',
  GEONAME: 'geonames.php',
  INTERNATIONAL_PHONE: 'international_phone.php',
  COUNTRIES: 'countries.php',
  SUB_DIVISIONS: 'subdivisions.php',
}

export const API_KPIS = {
  BASE_API_URL: `${DEFAULT_API_URL}/kpis/`,
  LIST: 'list.php',
  LPE_AVERAGE_POSITION: 'lpe-average-position.php',
  LPE_TOP_KEYWORDS_BY_CITY: 'lpe-top-keywords-by-city.php',
  LPE_TOP_PRINTS_BY_CITY: 'lpe-top-prints-by-city.php',
  LPE_LOCAL_PAGE_AUDIENCE: 'lpe-local-page-audience.php',
}

export const API_RADAR = {
  BASE_API_URL: `${DEFAULT_API_URL}/radar/`,
  APP: 'app.php',
}

export const API_ALGOLIA = {
  BASE_API_URL: `${DEFAULT_API_URL}/algolia/`,
  FRONT_OFFICE_INDICE: 'front-office_id_indice.php',
  LAUNCH_SYNCHRO: 'launch_synchro.php',
  SETTINGS: 'settings.php',
}

export const API_GMB = {
  BASE_API_URL: `${DEFAULT_API_URL}/gmb/`,
  HOLIDAYS: '/publicholidays?',
  CATEGORIES: 'categories.php',
  ATTRIBUTES: 'attributes.php',
  CHAINS: 'search_chains.php',
  ACCOUNTS: 'accounts.php',
  ACCOUNTS_FULL: 'accounts_full.php',
  ACCOUNT: 'account_id.php',
  ACCOUNT_NOTIFICATION: 'account_id_notification.php',
  LOCATIONS: 'locations.php',
  LOCATION: 'location_id.php',
  LOCATION_MAPPING: 'location_id_mapping.php',
  MEDIA: 'location_id_media.php',
  MEDIA_ID: 'location_id_media_id.php',
  QUESTIONS_ANSWERS: 'questions_answers.php',
  QUESTIONS_ANSWERS_ID: 'questions_answers_id.php',
  QUESTIONS_ANSWERS_ID_ANSWER_ID: 'questions_answers_id_answer_id.php',
  MEDIA_CUSTOMER: 'media_customer_id_takedown.php',
  INVITATIONS: 'invitations.php',
  SERVICES: 'location_services.php',
}
