import { sendGetRequest, sendPostRequest, sendPutRequest, sendDeleteRequest } from '@/services/api.service'
import { API_LPE, API_GMB } from '@/config/api.config'

export const locations = (clientId, urlParams) => {
  return sendGetRequest(
    encodeURI(`${API_LPE.BASE_API_URL}${API_LPE.LOCATIONS}?clientId=${clientId}${urlParams ? urlParams : ''}`)
  )
}

export const location = ({ clientId, id, frontOfficeId, localeId, isDefaultLocaleId }) => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.LOCATION}`, {
    params: { clientId, id, frontOfficeId, localeId, default: isDefaultLocaleId },
  })
}

export const locationUpdate = ({ clientId, id, frontOfficeId, localeId, isDefaultLocaleId }, data) => {
  return sendPostRequest(`${API_LPE.BASE_API_URL}${API_LPE.LOCATION}`, data, {
    params: { clientId, id, frontOfficeId, localeId, default: isDefaultLocaleId },
  })
}

export const locationCreate = (clientId, data) => {
  return sendPutRequest(`${API_LPE.BASE_API_URL}${API_LPE.LOCATIONS}?clientId=${clientId}`, data)
}

export const locationMapping = (clientId, accountGmb, account) => {
  return sendGetRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.LOCATION_MAPPING}?clientId=${clientId}&id=${accountGmb}&account=${account}`
  )
}

export const locationPostMapping = (clientId, accountGmb, account, placeId, data) => {
  return sendPostRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.LOCATION_MAPPING}?clientId=${clientId}&id=${accountGmb}&account=${account}&place_id=${placeId}`,
    {
      ids: data,
    }
  )
}

export const locationOpeningHours = (clientId, locationId) => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_OPENING_HOURS}?clientId=${clientId}&locationId=${locationId}`
  )
}

export const locationOpeningHourCreate = (clientId, locationId, openingHour) => {
  return sendPutRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_OPENING_HOURS}?clientId=${clientId}&locationId=${locationId}`,
    openingHour
  )
}

export const locationOpeningHourUpdate = (clientId, locationId, openingHourId, openingHour) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_OPENING_HOUR}?clientId=${clientId}&locationId=${locationId}&id=${openingHourId}`,
    openingHour
  )
}

export const locationOpeningHourDelete = (clientId, locationId, openingHourId) => {
  return sendDeleteRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_OPENING_HOUR}?clientId=${clientId}&locationId=${locationId}&id=${openingHourId}`
  )
}

export const locationUrls = (clientId, locationId) => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.LOCATION_URLS}?clientId=${clientId}&locationId=${locationId}`)
}

export const locationUrlCreate = (clientId, locationId, url) => {
  return sendPutRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_URLS}?clientId=${clientId}&locationId=${locationId}`,
    { ...url, locationId } //remove when the fix on api will be applied
  )
}

export const locationUrlUpdate = (clientId, locationId, urlId, url) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_URL}?clientId=${clientId}&locationId=${locationId}&id=${urlId}`,
    url
  )
}

export const locationUrlDelete = (clientId, locationId, urlId) => {
  return sendDeleteRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_URL}?clientId=${clientId}&locationId=${locationId}&id=${urlId}`
  )
}

export const locationExceptionalOpeningHours = (clientId, locationId, period = 'current') => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_EXCEPTIONAL_OPENING_HOURS}?clientId=${clientId}&locationId=${locationId}&period=${period}`
  )
}

export const locationExceptionalOpeningHourCreate = (clientId, locationId, exceptionalOpeningHour) => {
  return sendPutRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_EXCEPTIONAL_OPENING_HOURS}?clientId=${clientId}&locationId=${locationId}`,
    exceptionalOpeningHour
  )
}

export const locationExceptionalOpeningHourUpdate = (
  clientId,
  locationId,
  exceptionalOpeningHourId,
  exceptionalOpeningHour
) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_EXCEPTIONAL_OPENING_HOUR}?clientId=${clientId}&locationId=${locationId}&id=${exceptionalOpeningHourId}`,
    exceptionalOpeningHour
  )
}

export const locationExceptionalOpeningHourDelete = (clientId, locationId, exceptionalOpeningHourId) => {
  return sendDeleteRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_EXCEPTIONAL_OPENING_HOUR}?clientId=${clientId}&locationId=${locationId}&id=${exceptionalOpeningHourId}`
  )
}

export const locationCreateGeoip = () => {
  return sendGetRequest(`${API_LPE.NETLIFY_GEO_IP}`)
}

export const locationExternalIdInfo = (clientId, externalId) => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATIONS}?clientId=${clientId}&search=externalId:${externalId}`
  )
}
