import { sendGetRequest } from '@/services/api.service'
import { API_LPE } from '@/config/api.config'

export const leads = (clientId, urlParams) => {
  return sendGetRequest(encodeURI(`${API_LPE.BASE_API_URL}${API_LPE.LEADS}?clientId=${clientId}${urlParams}`))
}

export const lead = (clientId, locationId, leadId) => {
  return sendGetRequest(
    encodeURI(`${API_LPE.BASE_API_URL}${API_LPE.LEAD}?clientId=${clientId}&locationId=${locationId}&id=${leadId}`)
  )
}

export const leadHistory = (clientId, locationId, leadId) => {
  return sendGetRequest(
    encodeURI(
      `${API_LPE.BASE_API_URL}${API_LPE.LEAD_HISTORY}?clientId=${clientId}&locationId=${locationId}&leadId=${leadId}`
    )
  )
}

export const graph = (clientId, view, dateRange) => {
  return sendGetRequest(
    encodeURI(
      `${API_LPE.BASE_API_URL}${API_LPE.LEAD_DASHBOARD_GRAPH}?clientId=${clientId}&view=${view}&dateRange=${dateRange}`
    )
  )
}

export const summary = (clientId, dateRange) => {
  return sendGetRequest(
    encodeURI(`${API_LPE.BASE_API_URL}${API_LPE.LEAD_DASHBOARD_SUMMARY}?clientId=${clientId}&dateRange=${dateRange}`)
  )
}

export const overview = (clientId, dateRange, goal) => {
  return sendGetRequest(
    encodeURI(
      `${API_LPE.BASE_API_URL}${API_LPE.LEAD_DASHBOARD_OVERVIEW}?clientId=${clientId}&dateRange=${dateRange}&goal=${goal}`
    )
  )
}

export const network = (clientId, dateRange) => {
  return sendGetRequest(
    encodeURI(`${API_LPE.BASE_API_URL}${API_LPE.LEAD_DASHBOARD_NETWORK}?clientId=${clientId}&dateRange=${dateRange}`)
  )
}
