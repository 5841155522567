import { sendGetRequest, sendPostRequest, sendPutRequest } from '@/services/api.service'
import { API_LPE, API_ALGOLIA } from '@/config/api.config'

export const frontOffices = (clientId, urlParams) => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.FRONT_OFFICES}?clientId=${clientId}${urlParams}`)
}

export const frontOffice = (clientId, frontOfficeId, localeId) => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.FRONT_OFFICE}?clientId=${clientId}&id=${frontOfficeId}&localeId=${localeId}`
  )
}

export const frontOfficeUpdate = (clientId, frontOfficeId, localeId, localeDefault, data) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.FRONT_OFFICE}?clientId=${clientId}&id=${frontOfficeId}&localeId=${localeId}&default=${localeDefault}`,
    data
  )
}

export const frontOfficeCreate = (clientId, data) => {
  return sendPutRequest(`${API_LPE.BASE_API_URL}${API_LPE.FRONT_OFFICE}?clientId=${clientId}`, data)
}

export const featureList = clientId => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.FEATURE_LIST}?clientId=${clientId}`)
}

export const frontOfficeIndice = (id, clientId) => {
  return sendGetRequest(`${API_ALGOLIA.BASE_API_URL}${API_ALGOLIA.FRONT_OFFICE_INDICE}?id=${id}&clientId=${clientId}`)
}

export const frontOfficeFeatureList = (clientId, frontOfficeId) => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}front-office_id_factory.php?id=${frontOfficeId}&clientId=${clientId}`)
}
