import {
  userExpress,
  userExpressCreate,
  currentUserExpress,
  userExpressUpdate,
  campaign,
  campaignCreate,
  currentCampaign,
  updatecurrentCampaign,
  campaignHistoryInitialize,
  campaignHistory,
  campaignHistoryUpdate,
  tmpLocationOpeningHours,
  tmpLocationExceptionalOpeningHours,
  campaignSubmit,
} from '@/services/userexpress.service'
import { tableOptions } from '@/utils/table.util'

const SET_USER_EXPRESS = 'SET_USER_EXPRESS'
const SET_USER_EXPRESS_MOBILE = 'SET_USER_EXPRESS_MOBILE'
const SET_USER_EXPRESS_PAGINATION = 'SET_USER_EXPRESS_PAGINATION'
const SET_CURRENT_USER_EXPRESS = 'SET_CURRENT_USER_EXPRESS'
const UPDATE_CURRENT_USER_EXPRESS = 'UPDATE_CURRENT_USER_EXPRESS'
const SET_CAMPAIGN = 'SET_CAMPAIGN'
const SET_CAMPAIGN_MOBILE = 'SET_CAMPAIGN_MOBILE'
const SET_CAMPAIGN_PAGINATION = 'SET_CAMPAIGN_PAGINATION'
const SET_CURRENT_CAMPAIGN = 'SET_CURRENT_CAMPAIGN'
const SET_CAMPAIGN_HISTORY = 'SET_CAMPAIGN_HISTORY'
const UPDATE_CAMPAIGN_HISTORY = 'UPDATE_CAMPAIGN_HISTORY'
const CLEAR_CAMPAIGN_HISTORY = 'CLEAR_CAMPAIGN_HISTORY'
const SET_TMP_LOCATION_OPENING_HOURS = 'SET_TMP_LOCATION_OPENING_HOURS'
const SET_TMP_LOCATION_EXCEPTIONAL_OPENING_HOURS = 'SET_TMP_LOCATION_EXCEPTIONAL_OPENING_HOURS'

const state = () => {
  return {
    userExpress: [],
    userExpressMobile: [],
    userExpressPagination: {},
    currentUserExpress: {},
    campaign: [],
    campaignMobile: [],
    campaignPagination: {},
    currentCampaign: {},
    campaignHistory: [],
    tmpLocationOpeningHours: [],
    tmpLocationExceptionalOpeningHours: [],
  }
}

const mutations = {
  [SET_USER_EXPRESS]: (state, userExpress) => {
    state.userExpress = userExpress
  },
  [SET_USER_EXPRESS_MOBILE]: (state, { userExpress, reset }) => {
    if (reset) {
      state.userExpressMobile = []
    }
    state.userExpressMobile.push(...userExpress)
  },
  [SET_USER_EXPRESS_PAGINATION]: (state, pagination) => {
    state.userExpressPagination = pagination
  },
  [SET_CURRENT_USER_EXPRESS]: (state, userExpress) => {
    state.currentUserExpress = userExpress
  },
  [UPDATE_CURRENT_USER_EXPRESS]: (state, updatedUserExpress) => {
    state.currentUserExpress = updatedUserExpress
  },
  [SET_CAMPAIGN]: (state, campaign) => {
    state.campaign = campaign
  },
  [SET_CAMPAIGN_MOBILE]: (state, { campaign, reset }) => {
    if (reset) {
      state.campaignMobile = []
    }
    state.campaignMobile.push(...campaign)
  },
  [SET_CAMPAIGN_PAGINATION]: (state, pagination) => {
    state.campaignPagination = pagination
  },
  [SET_CURRENT_CAMPAIGN]: (state, campaign) => {
    state.currentCampaign = campaign
  },
  [SET_CAMPAIGN_HISTORY]: (state, history) => {
    state.campaignHistory.push(history)
  },
  [UPDATE_CAMPAIGN_HISTORY]: (state, history) => {
    state.campaignHistory = state.campaignHistory.map(h => {
      if (h.id === history.id) return history
      return h
    })
  },
  [CLEAR_CAMPAIGN_HISTORY]: state => {
    state.campaignHistory = []
  },
  [SET_TMP_LOCATION_OPENING_HOURS]: (state, openingHours) => {
    state.tmpLocationOpeningHours = openingHours
  },
  [SET_TMP_LOCATION_EXCEPTIONAL_OPENING_HOURS]: (state, exceptionalOpeningHours) => {
    state.tmpLocationExceptionalOpeningHours = exceptionalOpeningHours
  },
}

const actions = {
  getUserExpress: ({ commit, rootState }, { tableParams, resetUserExpress }) => {
    const urlParams = tableOptions(tableParams, '', 'userExpress')
    return userExpress(rootState.user.currentClient.id, rootState.user.currentUser.atoken, urlParams)
      .then(response => {
        commit(SET_USER_EXPRESS, response.data.results)
        commit(SET_USER_EXPRESS_MOBILE, { userExpress: response.data.results, reset: resetUserExpress })
        delete response.data.results
        commit(SET_USER_EXPRESS_PAGINATION, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  createUserExpress: ({ rootState }, { lastName, firstName, email, locations }) => {
    const data = {
      clientId: rootState.user.currentClient.id,
      administratorId: rootState.user.currentUser.atoken,
      firstName,
      lastName,
      email,
      locations,
    }
    return userExpressCreate(rootState.user.currentClient.id, rootState.user.currentUser.atoken, data)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(e => Promise.reject(e))
  },
  getCurrentUserExpress: ({ commit, rootState }, { userExpressId }) => {
    return currentUserExpress(userExpressId, rootState.user.currentClient.id, rootState.user.currentUser.atoken)
      .then(response => {
        commit(SET_CURRENT_USER_EXPRESS, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  updateUserExpress: ({ commit, rootState }, data) => {
    if (data.locations) {
      data.locations = data.locations.map(location => location.id)
    } else {
      data.locations = rootState.userexpress.currentUserExpress.locations.map(location => location.id)
    }
    const newCurrentUserExpress = Object.assign({ ...rootState.userexpress.currentUserExpress }, data)
    return userExpressUpdate(
      rootState.userexpress.currentUserExpress.id,
      rootState.user.currentClient.id,
      rootState.user.currentUser.atoken,
      newCurrentUserExpress
    )
      .then(response => {
        commit(UPDATE_CURRENT_USER_EXPRESS, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getCampaign: ({ commit, rootState }, { resetCampaign, tableParams, search }) => {
    const urlParams = tableOptions(tableParams, search, 'userExpress')
    return campaign(rootState.user.currentUser.atoken, urlParams)
      .then(response => {
        commit(SET_CAMPAIGN, response.data.results)
        commit(SET_CAMPAIGN_MOBILE, { campaign: response.data.results, reset: resetCampaign })
        delete response.data.results
        commit(SET_CAMPAIGN_PAGINATION, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  async createCampaign({ rootState }, { userExpressId, userExpressLocations }) {
    const dataCampaign = {
      administratorId: rootState.user.currentUser.atoken,
      userExpressId,
    }
    const response = await campaignCreate(rootState.user.currentUser.atoken, dataCampaign)
    const promises = userExpressLocations.map(location => {
      const dataHistory = {
        administratorId: rootState.user.currentUser.atoken,
        userExpressUpdateCampaignId: response.data.id,
        locationId: location.id,
        status: 'waiting',
      }
      return campaignHistoryInitialize(rootState.user.currentUser.atoken, dataHistory)
    })
    await Promise.all(promises)
  },
  updateCurrentCampaign: ({ dispatch, rootState }, { payload, filters }) => {
    return updatecurrentCampaign(
      payload.id,
      rootState.user.currentUser.atoken,
      rootState.user.currentClient.id,
      payload.data
    )
      .then(() => {
        dispatch('getCampaign', { resetCampaign: true, tableParams: { filters } })
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getCurrentCampaign: ({ commit, rootState }, { campaignId }) => {
    return currentCampaign(campaignId, rootState.user.currentUser.atoken, rootState.user.currentClient.id)
      .then(response => {
        commit(SET_CURRENT_CAMPAIGN, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getCampaignHistory: ({ commit, rootState }, { locationId, campaignId }) => {
    commit(CLEAR_CAMPAIGN_HISTORY)
    return campaignHistory(locationId, rootState.user.currentUser.atoken, campaignId)
      .then(response => {
        commit(SET_CAMPAIGN_HISTORY, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  submitCampaign: ({ state, rootState, commit }, { action, comment = null, history }) => {
    const dataSubmit = {
      administratorId: rootState.user.currentUser.atoken,
      userExpressId: state.currentUserExpress.id,
      status: action === 'accept' ? 'validated' : 'rejected',
      comment,
      token: state.currentCampaign.token,
    }
    const dataHistory = {
      ...history,
      status: action === 'accept' ? 'validated' : 'rejected',
      comment,
    }
    return campaignSubmit(
      rootState.user.currentUser.atoken,
      action,
      history.userExpressUpdateCampaignId,
      history.locationId,
      dataSubmit
    )
      .then(() => {
        return campaignHistoryUpdate(rootState.user.currentUser.atoken, history.id, history.locationId, dataHistory)
      })
      .then(response => {
        commit(UPDATE_CAMPAIGN_HISTORY, response.data)
        return Promise.resolve(response.data)
      })
      .catch(e => Promise.reject(e))
  },
  getTmpLocationOpeningHours: ({ commit }, locationId) => {
    return tmpLocationOpeningHours(locationId)
      .then(response => {
        commit(SET_TMP_LOCATION_OPENING_HOURS, response.data.results)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getTmpLocationExceptionalOpeningHours: ({ commit }, locationId) => {
    return tmpLocationExceptionalOpeningHours(locationId)
      .then(response => {
        commit(SET_TMP_LOCATION_EXCEPTIONAL_OPENING_HOURS, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
}

const getters = {
  userExpressFilteredActive: state => {
    let filteredUserExpress = []
    state.userExpress.forEach(ue => {
      if (ue.campaignActive === true) {
        filteredUserExpress.push(ue)
      }
    })
    return filteredUserExpress
  },
  userExpressFilteredDeactivate: state => {
    let filteredUserExpress = []
    state.userExpress.forEach(ue => {
      if (ue.campaignActive === false) {
        filteredUserExpress.push(ue)
      }
    })
    return filteredUserExpress
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
