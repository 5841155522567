import Modal from './Modal.vue'

const defaultComponentName = 'Modal'

const Plugin = {
  install(Vue, options = {}) {
    /**
     * Makes sure that plugin can be installed only once
     */
    if (this.installed) {
      return
    }

    this.installed = true
    this.event = new Vue()

    const componentName = options.componentName || defaultComponentName
    /**
     * Plugin API
     */
    const showStaticModal = (modal, params) => {
      Plugin.event.$emit('toggle', modal, true, params)
    }

    Vue.prototype.$modal = {
      get context() {
        return {
          componentName,
          options,
        }
      },

      get subscription() {
        return Plugin.event
      },

      show(modal, ...args) {
        switch (typeof modal) {
          case 'string': {
            return showStaticModal(modal, ...args)
          }

          default: {
            return false
          }
        }
      },

      hide(name, params) {
        Plugin.event.$emit('toggle', name, false, params)
      },

      toggle(name, params) {
        Plugin.event.$emit('toggle', name, undefined, params)
      },
    }
    /**
     * Sets custom component name (if provided)
     */
    Vue.component(componentName, Modal)
  },
}

export default Plugin
