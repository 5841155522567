<template>
  <component
    :is="isLink ? 'a' : 'button'"
    :href="href ? href : null"
    class="ui-button"
    :class="buttonClasses"
    :type="type"
    :aria-label="iconOnly ? label : null"
    :aria-labelledby="!iconOnly ? label : null"
    :target="href ? '_blank' : null"
    :disabled="disabled"
    @click="onClick"
  >
    <span class="ui-button__icon ui-button__icon--default backoffice-icons" v-if="icon && !svg && !reverse">
      {{ icon }}
    </span>
    <img
      :src="require(`@/assets/img/svg/${svg}.svg`)"
      class="ui-button__svg ui-button__svg--default"
      :class="{
        'ui-button__svg--disabled': disabled,
      }"
      v-if="!icon && svg && !reverse"
    />
    <span class="ui-button__label" v-if="!iconOnly">
      {{ label }}
    </span>
    <span class="ui-button__icon ui-button__icon--reverse backoffice-icons" v-if="icon && !svg && reverse">
      {{ icon }}
    </span>
    <img
      :src="require(`@/assets/img/svg/${svg}.svg`)"
      class="ui-button__svg ui-button__svg--reverse"
      :class="{
        'ui-button__svg--disabled': disabled,
      }"
      v-if="!icon && svg && reverse"
    />
    <ui-loader v-if="loaderActive && disabled" class="ui-button--loader" stroke="#007bb5" :stroke-width="4"></ui-loader>
  </component>
</template>

<script>
import UiLoader from '@/components/UI/Loader.vue'

export default {
  name: 'Button',
  components: {
    UiLoader,
  },
  props: {
    button: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    iconOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: String,
      required: false,
      default: '',
    },
    svg: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    isLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    mobileToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
    loaderActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    buttonClasses() {
      const classes = [`ui-button--${this.button}`]

      if (this.variant) {
        classes.push(`ui-button--${this.button}--${this.variant}`)
      }

      if (!this.iconOnly && !this.mobileToggle && (this.icon || this.svg)) {
        classes.push(`ui-button--text-icon`)
      } else if (this.mobileToggle && (this.icon || this.svg)) {
        classes.push(`ui-button--mobile-toggle`)
      } else if (this.iconOnly && (this.icon || this.svg)) {
        classes.push(`ui-button--icon`)
      }

      return classes
    },
  },
  methods: {
    onClick($event) {
      /**
       * Emitted when the button is clicked.
       * @event click
       * @type {Event}
       */
      this.$emit('click', $event)
    },
  },
}
</script>

<style lang="scss">
.ui-button {
  &--cta {
    @include button-cta;
  }

  &--primary {
    @include button-primary;
  }

  &--secondary {
    @include button-secondary;
  }

  &--icon {
    @include button-icon;
  }

  &--text-icon {
    @include button-text-icon;
  }

  &--mobile-toggle {
    @include button-mobile-toggle;
  }

  &--loader {
    margin-left: 6px;
    width: 22px;
    height: 22px;
  }
}
</style>
