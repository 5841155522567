import axios from 'axios'
import store from '@/store'

const api = axios.create({
  baseUlr: '/',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': 'Authorization',
  },
})

const requestSuccess = config => {
  const jwtToken = localStorage.getItem('jwt_token')
  const userToken = localStorage.getItem('user_token')

  if (jwtToken !== null && userToken !== null && config.url !== '/user/login.php') {
    config.headers.authorization = `Bearer ${jwtToken}`
    config.headers.usertoken = userToken
  }
  return config
}

const requestError = error => Promise.reject(error)

const responseSuccess = response => {
  if (response.data.data?.last_jwt_token ?? null) {
    localStorage.setItem('jwt_token', response.data.data.last_jwt_token)
  }
  return response
}

const responseError = error => {
  if (error.response.status === 401) {
    store.dispatch('user/userLogout').then(() => window.location.assign('/login'))
  }
  return Promise.reject(error)
}

api.interceptors.request.use(requestSuccess, requestError)
api.interceptors.response.use(responseSuccess, responseError)

export const sendPostRequest = (url, data, config = null) => {
  return api
    .post(url, data, config)
    .then(resp => Promise.resolve(resp.data))
    .catch(err => Promise.reject(err))
}

export const sendGetRequest = (url, config = null) => {
  return api
    .get(url, config)
    .then(resp => Promise.resolve(resp.data))
    .catch(err => Promise.reject(err))
}

export const sendPutRequest = (url, data, config = null) => {
  return api
    .put(url, data, config)
    .then(resp => Promise.resolve(resp.data))
    .catch(err => Promise.reject(err))
}

export const sendDeleteRequest = (url, config = null) => {
  return api
    .delete(url, config)
    .then(resp => Promise.resolve(resp.data))
    .catch(err => Promise.reject(err))
}
