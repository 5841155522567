import Vue from 'vue'
import App from './App.vue'
import VueAnalytics from 'vue-ua'
import router from './router'
import store from './store'
import i18n from './i18n'
import '@/assets/scss/global.scss'

import VueTooltip from 'v-tooltip'
import Vuelidate from 'vuelidate'
import VueGoodTablePlugin from 'vue-good-table'
import VueModal from '@/plugins/Modal'
import VueRx from 'vue-rx'
import VueClipper from 'vuejs-clipper'
import * as VueGoogleMaps from 'gmap-vue'
import VueLogger from 'vuejs-logger'
import packageJson from '../package.json'

import { map } from '@/config/map.config'

const isProd = process.env.CONTEXT === 'production'

Vue.config.productionTip = false

Vue.use(VueTooltip)
Vue.use(Vuelidate)
Vue.use(VueGoodTablePlugin)
Vue.use(VueModal)
Vue.use(VueRx)
Vue.use(VueClipper)
Vue.use(VueGoogleMaps, {
  load: {
    key: isProd ? map.apiKey : 'AIzaSyBfKq0YFVoeTfM2OaJYuFqAC3ZCwt0AURQ',
    libraries: 'geometry,places',
  },
})
Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: isProd ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
})

Vue.use(VueAnalytics, {
  appName: 'backoffice-frontend',
  appVersion: packageJson.version,
  trackingId: 'UA-121945988-4',
  debug: !isProd,
  vueRouter: router,
  trackPage: true,
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#backoffice')
