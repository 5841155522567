import { sendGetRequest, sendPostRequest, sendPutRequest } from '@/services/api.service'
import { API_LPE } from '@/config/api.config'

export const userExpress = (clientId, administratorId, urlParams) => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.USER_EXPRESS}?clientId=${clientId}&administratorId=${administratorId}${urlParams}`
  )
}

export const userExpressCreate = (clientId, administratorId, data) => {
  return sendPutRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.USER_EXPRESS}?clientId=${clientId}&administratorId=${administratorId}`,
    data
  )
}

export const currentUserExpress = (userExpressId, clientId, administratorId) => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.USER_EXPRESS_ID}?id=${userExpressId}&clientId=${clientId}&administratorId=${administratorId}`
  )
}

export const userExpressUpdate = (userExpressId, clientId, administratorId, data) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.USER_EXPRESS_ID}?id=${userExpressId}&clientId=${clientId}&administratorId=${administratorId}`,
    data
  )
}

export const campaign = (administratorId, urlParams) => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.USER_EXPRESS_CAMPAIGN}?administratorId=${administratorId}${urlParams}`
  )
}

export const currentCampaign = (campaignId, administratorId, clientId) => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.USER_EXPRESS_CAMPAIGN_ID}?clientId=${clientId}&id=${campaignId}&administratorId=${administratorId}`
  )
}

export const updatecurrentCampaign = (campaignId, administratorId, clientId, data) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.USER_EXPRESS_CAMPAIGN_ID}?clientId=${clientId}&id=${campaignId}&administratorId=${administratorId}`,
    data
  )
}

export const campaignCreate = (administratorId, data) => {
  return sendPutRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.USER_EXPRESS_CAMPAIGN}?administratorId=${administratorId}`,
    data
  )
}

export const campaignHistory = (locationId, administratorId, id) => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.USER_EXPRESS_CAMPAIGN_HISTORY}?locationId=${locationId}&administratorId=${administratorId}&campaignId=${id}`
  )
}

export const campaignHistoryInitialize = (administratorId, data) => {
  return sendPutRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.USER_EXPRESS_CAMPAIGN_HISTORY}?administratorId=${administratorId}&id=0`,
    data
  )
}

export const campaignHistoryUpdate = (administratorId, id, locationId, data) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.USER_EXPRESS_CAMPAIGN_HISTORY}?administratorId=${administratorId}&locationId=${locationId}&id=${id}`,
    data
  )
}

export const campaignSubmit = (administratorId, action, id, locationId, data) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.USER_EXPRESS_CAMPAIGN_SUBMIT}?administratorId=${administratorId}&action=${action}&locationId=${locationId}&id=${id}`,
    data
  )
}

export const tmpLocationOpeningHours = locationId => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.TMP_LOCATION_OPENING_HOURS}?locationId=${locationId}`)
}

export const tmpLocationExceptionalOpeningHours = locationId => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.TMP_LOCATION_EXCEPTIONAL_OPENING_HOURS}?locationId=${locationId}`
  )
}
