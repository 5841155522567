import {
  listQuestionsAnswers,
  getQuestionsAnswersDetail,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  createAnswer,
  updateAnswer,
  deleteAnswer,
} from '../../services/qa.service'

const displayErrorLogNoProduction = error => {
  if (process.env.CONTEXT !== 'production') {
    /* eslint-disable-next-line no-console */
    console.error(error)
  }
}

const SET_CURRENT_QUESTIONS_ANSWERS = 'SET_CURRENT_QUESTIONS_ANSWERS'
const SET_CURRENT_QUESTIONS_ANSWERS_DETAIL = 'SET_CURRENT_QUESTIONS_ANSWERS_DETAIL'
const ADD_QUESTION = 'ADD_QUESTION'
const UPDATE_QUESTION = 'UPDATE_QUESTION'
const DELETE_QUESTION = 'DELETE_QUESTION'
const ADD_RESPONSE = 'ADD_RESPONSE'
const UPDATE_RESPONSE = 'UPDATE_RESPONSE'
const DELETE_RESPONSE = 'DELETE_RESPONSE'

const initialState = () => {
  return {
    currentQuestionsAnswers: { data: [] },
    currentQuestionsAnswersDetail: null,
  }
}

const updatedQuestions = (questions, updatedQuestion) => {
  const questionsCpy = [...questions]
  questionsCpy[questionsCpy.findIndex(question => question.name === updatedQuestion.name)] = updatedQuestion
  return questionsCpy
}

const addedResponse = (questions, newResponse) => {
  const questionsCpy = [...questions]
  const questionIndex = questionsCpy.findIndex(question => question.name === newResponse.name.split('/answers')[0])
  questionsCpy[questionIndex].topAnswers = [...questionsCpy[questionIndex].topAnswers, newResponse]
  return questionsCpy
}

const updatedResponses = (questions, updatedResponse) => {
  const questionIndex = questions.findIndex(question => question.name === updatedResponse.name.split('/answers')[0])
  const responseIndex = questions[questionIndex].topAnswers.findIndex(
    response => response.name === updatedResponse.name
  )
  const questionsCpy = [...questions]
  questionsCpy[questionIndex].topAnswers[responseIndex] = updatedResponse
  return questionsCpy
}

const deletedResponse = (questions, responseNameToDelete) => {
  const questionsCpy = [...questions]
  const questionIndex = questionsCpy.findIndex(question => question.name === responseNameToDelete.split('/answers')[0])
  questionsCpy[questionIndex].topAnswers = questionsCpy[questionIndex].topAnswers.filter(
    response => response.name !== responseNameToDelete
  )
  return questionsCpy
}

const mutations = {
  [SET_CURRENT_QUESTIONS_ANSWERS]: (state, questionsAnswers) => {
    state.currentQuestionsAnswers = questionsAnswers
  },
  [SET_CURRENT_QUESTIONS_ANSWERS_DETAIL]: (state, questionsAnswersDetail) => {
    state.currentQuestionsAnswersDetail = questionsAnswersDetail
  },
  [ADD_QUESTION]: (state, data) => {
    state.currentQuestionsAnswers.data = [...state.currentQuestionsAnswers.data, data]
    state.currentQuestionsAnswersDetail = data
  },
  [UPDATE_QUESTION]: (state, data) => {
    state.currentQuestionsAnswers.data = updatedQuestions(state.currentQuestionsAnswers.data, data)
    state.currentQuestionsAnswersDetail = data
  },
  [DELETE_QUESTION]: (state, location) => {
    state.currentQuestionsAnswers.data = state.currentQuestionsAnswers.data.filter(
      question => question.location !== location
    )
    state.currentQuestionsAnswersDetail =
      location === state.currentQuestionsAnswersDetail.name.split('/question')[0]
        ? null
        : state.currentQuestionsAnswersDetail
  },
  [ADD_RESPONSE]: (state, data) => {
    state.currentQuestionsAnswers.data = addedResponse(state.currentQuestionsAnswers.data, data)
    state.currentQuestionsAnswersDetail = {
      ...state.currentQuestionsAnswersDetail,
      topAnswers: [...state.currentQuestionsAnswersDetail.topAnswers, data],
    }
  },
  [UPDATE_RESPONSE]: (state, data) => {
    state.currentQuestionsAnswers.data = updatedResponses(state.currentQuestionsAnswers.data, data)
    state.currentQuestionsAnswersDetail = updatedResponses([state.currentQuestionsAnswersDetail], data)[0]
  },
  [DELETE_RESPONSE]: (state, id) => {
    state.currentQuestionsAnswers.data = deletedResponse(state.currentQuestionsAnswers.data, id)
    const result = deletedResponse([state.currentQuestionsAnswersDetail], id)
    state.currentQuestionsAnswersDetail = result.length > 0 ? result[0] : null
  },
}

const actions = {
  loadQuestionsAnswers: async ({ commit, rootState }, { account, location, page, size }) => {
    try {
      const params = { clientId: rootState.user.currentClient.id, account: account, location, page, size }
      const { data } = await listQuestionsAnswers(params)
      const { results, ...rest } = data
      commit(SET_CURRENT_QUESTIONS_ANSWERS, {
        data: results,
        pagination: rest,
      })
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  getQuestionsAnswersDetail: async ({ commit, rootState }, { account, location, id, page, size }) => {
    try {
      const { data } = await getQuestionsAnswersDetail({
        clientId: rootState.user.currentClient.id,
        account,
        location,
        id,
        page,
        size,
      })
      commit(SET_CURRENT_QUESTIONS_ANSWERS_DETAIL, data)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  createQuestion: async ({ commit, rootState }, { account, location, text }) => {
    try {
      const { data } = await createQuestion({ clientId: rootState.user.currentClient.id, account, location, text })
      commit(ADD_QUESTION, data)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  updateQuestion: async ({ commit, rootState }, { account, location, text }) => {
    try {
      const { data } = await updateQuestion({ clientId: rootState.user.currentClient.id, account, location, text })
      commit(UPDATE_QUESTION, data)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  deleteQuestion: async ({ commit, rootState }, { account, location, text }) => {
    try {
      await deleteQuestion({ clientId: rootState.user.currentClient.id, account, location, text })
      commit(DELETE_QUESTION, location)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  createAnswer: async ({ commit, rootState }, { account, location, id, text }) => {
    try {
      const { data } = await createAnswer({ clientId: rootState.user.currentClient.id, account, location, id, text })
      commit(ADD_RESPONSE, data)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  updateAnswer: async ({ commit, rootState }, { account, location, id, text }) => {
    try {
      const { data } = await updateAnswer({ clientId: rootState.user.currentClient.id, account, location, id, text })
      commit(UPDATE_RESPONSE, data)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  deleteAnswer: async ({ commit, rootState }, { account, location, id, text }) => {
    try {
      await deleteAnswer({ clientId: rootState.user.currentClient.id, account, location, id, text })
      commit(DELETE_RESPONSE, id)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
}
