import {
  frontOffices,
  frontOffice,
  frontOfficeUpdate,
  frontOfficeCreate,
  frontOfficeFeatureList,
} from '@/services/frontoffice.service'
import { publishing, getFrontOfficeSettings, updateFrontOfficeSettings } from '@/services/algolia.service'
import { tableOptions } from '@/utils/table.util'

const SET_FRONT_OFFICES = 'SET_FRONT_OFFICES'
const SET_FRONT_OFFICES_MOBILE = 'SET_FRONT_OFFICES_MOBILE'
const SET_FRONT_OFFICES_PAGINATION = 'SET_FRONT_OFFICES_PAGINATION'
const SET_CURRENT_FRONT_OFFICE = 'SET_CURRENT_FRONT_OFFICE'
const SET_CURRENT_FRONT_OFFICE_SETTINGS = 'SET_CURRENT_FRONT_OFFICE_SETTINGS'
const SET_CURRENT_FRONT_OFFICE_FEATURE_LIST = 'SET_CURRENT_FRONT_OFFICE_FEATURE_LIST'

const state = () => {
  return {
    frontOffices: [],
    frontOfficesPagination: {},
    frontOfficesMobile: [],
    currentFrontOffice: {},
    currentFrontOfficeSettings: {},
    currentFrontOfficeFeatureList: [],
  }
}

const mutations = {
  [SET_FRONT_OFFICES]: (state, frontOffices) => {
    state.frontOffices = frontOffices
  },
  [SET_FRONT_OFFICES_MOBILE]: (state, { frontOffices, reset }) => {
    if (reset) {
      state.frontOfficesMobile = []
    }
    state.frontOfficesMobile.push(...frontOffices)
  },
  [SET_FRONT_OFFICES_PAGINATION]: (state, pagination) => {
    state.frontOfficesPagination = pagination
  },
  [SET_CURRENT_FRONT_OFFICE]: (state, frontOffice) => {
    state.currentFrontOffice = frontOffice
    localStorage.setItem('frontoffice', JSON.stringify(frontOffice))
  },
  [SET_CURRENT_FRONT_OFFICE_SETTINGS]: (state, frontOfficeSettings) => {
    state.currentFrontOfficeSettings = frontOfficeSettings
  },
  [SET_CURRENT_FRONT_OFFICE_FEATURE_LIST]: (state, frontOfficeFeatureList) => {
    state.currentFrontOfficeFeatureList = frontOfficeFeatureList
  },
}

const actions = {
  getFrontOffices: ({ commit, rootState }, { tableParams = null, resetFrontOffices, search }) => {
    const urlParams = tableOptions(tableParams, search, 'frontOffices')
    return frontOffices(rootState.user.currentClient.id, urlParams)
      .then(response => {
        commit(SET_FRONT_OFFICES, response.data.results)
        commit(SET_FRONT_OFFICES_MOBILE, { frontOffices: response.data.results, reset: resetFrontOffices })
        delete response.data.results
        commit(SET_FRONT_OFFICES_PAGINATION, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getFrontOffice: ({ commit, rootState }, { frontOfficeId, localeId = '' }) => {
    return frontOffice(rootState.user.currentClient.id, frontOfficeId, localeId).then(frontOffice => {
      commit(SET_CURRENT_FRONT_OFFICE, frontOffice.data)
      return Promise.resolve()
    })
  },
  updateFrontOffice: ({ commit, state, rootState }, data) => {
    return frontOfficeUpdate(
      rootState.user.currentClient.id,
      state.currentFrontOffice.id,
      state.currentFrontOffice.localeId,
      state.currentFrontOffice.defaultLocaleId === state.currentFrontOffice.localeId,
      data
    ).then(response => {
      commit(SET_CURRENT_FRONT_OFFICE, response.data)
      return Promise.resolve()
    })
  },
  updateCurrentFrontOffice: ({ dispatch }, frontOffice) => {
    return dispatch('getFrontOffice', { frontOfficeId: frontOffice.id, localeId: frontOffice.defaultLocaleId })
  },
  updateCurrentFrontOfficeLocale: ({ state, dispatch }, locale) => {
    return dispatch('getFrontOffice', { frontOfficeId: state.currentFrontOffice.id, localeId: locale.localeId })
  },
  createFrontOffice: (_, data) => {
    return frontOfficeCreate(data.clientId, data).then(response => {
      return Promise.resolve(response.data)
    })
  },
  publishFrontOffice: ({ state, rootState }) => {
    return publishing(rootState.user.currentClient.id, state.currentFrontOffice.id)
      .then(() => Promise.resolve())
      .catch(() => Promise.reject())
  },
  getFrontOfficeSettings: async ({ commit, state, rootState }) => {
    try {
      const { data } = await getFrontOfficeSettings(rootState.user.currentClient.id, state.currentFrontOffice.id)
      commit(SET_CURRENT_FRONT_OFFICE_SETTINGS, data.results)
    } catch {
      return false
    }
  },
  updateFrontOfficeSettings: async ({ commit, state, rootState }, newData) => {
    try {
      const { data } = await updateFrontOfficeSettings(
        rootState.user.currentClient.id,
        state.currentFrontOffice.id,
        newData
      )
      commit(SET_CURRENT_FRONT_OFFICE_SETTINGS, data.results)
    } catch {
      return false
    }
  },
  getFrontOfficeFeaturesList: async ({ commit, state }) => {
    try {
      let detailList = []
      const { data } = await frontOfficeFeatureList(state.currentFrontOffice.clientId, state.currentFrontOffice.id)
      data.customListsGlobal
        .filter(itemCustomLists => itemCustomLists.locale === state.currentFrontOffice.localeCode)
        .forEach(itemListDefault => (detailList = itemListDefault.lists))

      commit(SET_CURRENT_FRONT_OFFICE_FEATURE_LIST, detailList)
    } catch {
      commit(SET_CURRENT_FRONT_OFFICE_FEATURE_LIST, [])
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
