/* eslint-disable */
import dayjs from 'dayjs'
import { sendGetRequest } from '@/services/api.service'
import { API_KPIS } from '@/config/api.config'

export const list = async clientId => {
  const { data } = await sendGetRequest(`${API_KPIS.BASE_API_URL}${API_KPIS.LIST}?clientId=${clientId}`)
  return data
}

const MIN_START_DATE = '2018-01-01'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const startOfMonth = () => dayjs().startOf('month').format(DATE_FORMAT)
export const startOfYear = () => dayjs().startOf('year').format(DATE_FORMAT)
const currentDate = () => dayjs().format(DATE_FORMAT)

export const getAveragePosition = async (clientId, startDate = MIN_START_DATE, endDate = currentDate()) => {
  const { data } = await sendGetRequest(`${API_KPIS.BASE_API_URL}${API_KPIS.LPE_AVERAGE_POSITION}`, {
    params: {
      clientId,
      startDate,
      endDate,
    },
  })
  return data
}

export const getTopPrintsByCity = async (clientId, startDate = MIN_START_DATE, endDate = currentDate()) => {
  const { data } = await sendGetRequest(`${API_KPIS.BASE_API_URL}${API_KPIS.LPE_TOP_PRINTS_BY_CITY}`, {
    params: {
      clientId,
      startDate,
      endDate,
    },
  })
  return data
}

export const getTopKeywordsByCity = async (clientId, startDate = MIN_START_DATE, endDate = currentDate()) => {
  const { data } = await sendGetRequest(`${API_KPIS.BASE_API_URL}${API_KPIS.LPE_TOP_KEYWORDS_BY_CITY}`, {
    params: {
      clientId,
      startDate,
      endDate,
    },
  })
  return data
}

export const getLocalPageAudience = async (clientId, startDate = MIN_START_DATE, endDate = currentDate()) => {
  const { data } = await sendGetRequest(`${API_KPIS.BASE_API_URL}${API_KPIS.LPE_LOCAL_PAGE_AUDIENCE}`, {
    params: {
      clientId,
      startDate,
      endDate,
    },
  })
  return data
}

export const getLocalPageAudienceSinceLastMonth = clientId =>
  getLocalPageAudience(clientId, dayjs().startOf('month').format(DATE_FORMAT))

export const getLocalPageAudienceSinceBeginningOfTheYear = clientId =>
  getLocalPageAudience(clientId, dayjs().startOf('year').format(DATE_FORMAT))
