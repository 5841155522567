import { sendGetRequest, sendPostRequest, sendDeleteRequest, sendPutRequest } from '@/services/api.service'
import { API_LPE } from '@/config/api.config'

export const clients = urlParams => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.CLIENTS}?${urlParams}`)
}

export const client = clientId => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.CLIENT}?id=${clientId}`)
}

export const clientUpdate = (clientId, data) => {
  return sendPostRequest(`${API_LPE.BASE_API_URL}${API_LPE.CLIENT}?id=${clientId}`, data)
}

export const clientCreate = data => {
  return sendPutRequest(`${API_LPE.BASE_API_URL}${API_LPE.CLIENT}`, data)
}

export const locales = () => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.LOCALES}?size=200&search=status:1`)
}

export const clientFeatureList = clientId => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.CLIENT_FEATURES_LIST}?clientId=${clientId}`)
}

export const clientFeatureListCreate = (clientId, featureList) => {
  return sendPutRequest(`${API_LPE.BASE_API_URL}${API_LPE.CLIENT_FEATURES_LIST}?clientId=${clientId}`, featureList)
}

export const clientFeatureListUpdate = (clientId, featureListId, featureList) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.CLIENT_FEATURE_LIST}?clientId=${clientId}&id=${featureListId}`,
    featureList
  )
}

export const clientFeatureListDelete = (clientId, featureListId) => {
  return sendDeleteRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.CLIENT_FEATURE_LIST}?clientId=${clientId}&id=${featureListId}`
  )
}
