import Vue from 'vue'
import Vuex from 'vuex'
import backoffice from './modules/backoffice.store'
import user from './modules/user.store'
import location from './modules/location.store'
import frontoffice from './modules/frontoffice.store'
import client from './modules/client.store'
import tool from './modules/tool.store'
import kpis from './modules/kpis.store'
import radar from './modules/radar.store'
import gmb from './modules/gmb.store'
import qa from './modules/qa.store'
import lead from './modules/lead.store'
import userexpress from './modules/userexpress.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    backoffice,
    user,
    location,
    frontoffice,
    client,
    tool,
    kpis,
    radar,
    gmb,
    qa,
    lead,
    userexpress,
  },
  strict: process.env.NODE_ENV !== 'production',
})
