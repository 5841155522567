import {
  geoJsons,
  getCategories,
  attributes,
  chains,
  accounts,
  getFullAccounts,
  locations,
  location as fetchLocation,
  locationMedias,
  locationUpdate,
  listInvitations,
  getExceptionnalHoursApi,
  deleteLocationMedia,
  getServicesFromCategory,
} from '@/services/gmb.service'

import { tableOptions } from '@/utils/table.util'
import AVAILABLES_COUNTRIES from '@/lovs/gmbAvailableCountries'
import LANGUAGE_CODES from '@/lovs/gmbLanguageCodes'

const SET_WORLD_GEO_JSONS = 'SET_WORLD_GEO_JSONS'
const SET_CATEGORIES = 'SET_CATEGORIES'
const SET_SERVICES = 'SET_SERVICES'
const SET_ATTRIBUTES = 'SET_ATTRIBUTES'
const SET_ATTRIBUTES_PAGINATION = 'SET_ATTRIBUTES_PAGINATION'
const SET_CHAINS = 'SET_CHAINS'
const SET_CHAINS_PAGINATION = 'SET_CHAINS_PAGINATION'
const SET_ACCOUNTS = 'SET_ACCOUNTS'
const SET_ACCOUNTS_MOBILE = 'SET_ACCOUNTS_MOBILE'
const SET_ACCOUNTS_PAGINATION = 'SET_ACCOUNTS_PAGINATION'
const SET_ADMIN_ACCOUNTS = 'SET_ADMIN_ACCOUNTS'
const SET_ADMIN_ACCOUNTS_PAGINATION = 'SET_ADMIN_ACCOUNTS_PAGINATION'
const SET_EXCEPTIONNAL_HOURS_API = 'SET_EXCEPTIONNAL_HOURS_API'
const SET_LOCATIONS_GMB = 'SET_LOCATIONS_GMB'
const RESET_LOCATIONS_GMB = 'RESET_LOCATIONS_GMB'
const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION'
const SET_CURRENT_LOCATION_MEDIAS = 'SET_CURRENT_LOCATION_MEDIAS'
const UPDATE_CURRENT_LOCATION = 'UPDATE_CURRENT_LOCATION'
const SET_INVITATIONS = 'SET_INVITATIONS'
const RESET_CURRENT_LOCATION_MEDIAS = 'RESET_CURRENT_LOCATION_MEDIAS'
const RESET_CURRENT_LOCATION = 'RESET_CURRENT_LOCATION'

const initialState = () => {
  return {
    worldGeoJsons: [],
    availablesCountries: AVAILABLES_COUNTRIES,
    languageCodes: LANGUAGE_CODES,
    categories: { data: [] },
    attributes: [],
    attributesPagination: {},
    chains: [],
    chainsPagination: {},
    accounts: [],
    accountsMobile: [],
    accountsPagination: {},
    adminAccounts: [],
    adminAccountsPagination: {},
    locationsGmb: { data: [] },
    currentLocation: {},
    currentLocationMedias: {},
    exceptionnalHoursApi: {},
    invitations: {},
    services: [],
  }
}

const mutations = {
  [SET_WORLD_GEO_JSONS]: (state, geoJsons) => {
    state.worldGeoJsons = geoJsons
  },
  [SET_CATEGORIES]: (state, categories) => {
    state.categories = categories
  },
  [SET_SERVICES]: (state, services) => {
    state.services = services
  },
  [SET_ATTRIBUTES]: (state, attributes) => {
    state.attributes = attributes
  },
  [SET_ATTRIBUTES_PAGINATION]: (state, pagination) => {
    state.attributesPagination = pagination
  },
  [SET_CHAINS]: (state, chains) => {
    state.chains = chains
  },
  [SET_CHAINS_PAGINATION]: (state, pagination) => {
    state.chainsPagination = pagination
  },
  [SET_ACCOUNTS]: (state, accounts) => {
    state.accounts = accounts
  },
  [SET_ACCOUNTS_MOBILE]: (state, { accounts, reset }) => {
    if (reset) {
      state.accountsMobile = []
    }
    state.accountsMobile.push(...accounts)
  },
  [SET_ACCOUNTS_PAGINATION]: (state, pagination) => {
    state.accountsPagination = pagination
  },
  [SET_ADMIN_ACCOUNTS]: (state, accounts) => {
    state.adminAccounts = accounts
  },
  [SET_ADMIN_ACCOUNTS_PAGINATION]: (state, pagination) => {
    state.adminAccountsPagination = pagination
  },
  [SET_LOCATIONS_GMB]: (state, locations) => {
    state.locationsGmb = locations
  },
  [SET_CURRENT_LOCATION]: (state, location) => {
    state.currentLocation = location
  },
  [SET_CURRENT_LOCATION_MEDIAS]: (state, medias) => {
    state.currentLocationMedias = medias
  },
  [UPDATE_CURRENT_LOCATION]: (state, updatedLocation) => {
    state.currentLocation = Object.assign(state.currentLocation, updatedLocation)
  },
  [SET_EXCEPTIONNAL_HOURS_API]: (state, exceptionnalHoursApi) => {
    state.exceptionnalHoursApi = exceptionnalHoursApi
  },
  [SET_INVITATIONS]: (state, invitations) => {
    state.invitations = invitations
  },
  [RESET_CURRENT_LOCATION_MEDIAS]: state => {
    state.currentLocationMedias = initialState().currentLocationMedias
  },
  [RESET_CURRENT_LOCATION]: state => {
    state.currentLocation = initialState().currentLocation
  },
  [RESET_LOCATIONS_GMB]: state => {
    state.locationsGmb = initialState().locationsGmb
  },
}

const actions = {
  loadLocationMedias: async ({ commit, rootState }, { locationName, currentAccountName }) => {
    commit(RESET_CURRENT_LOCATION_MEDIAS)
    const { data } = await locationMedias(rootState.user.currentClient.id, locationName, currentAccountName)
    commit(SET_CURRENT_LOCATION_MEDIAS, data)
  },
  deleteLocationMedia: async ({ rootState }, { accountName, locationMediaId }) => {
    await deleteLocationMedia(rootState.user.currentClient.id, accountName, locationMediaId)
  },
  loadLocation: async ({ commit, rootState }, { locationName, currentAccountName }) => {
    commit(RESET_CURRENT_LOCATION)
    const { data } = await fetchLocation(rootState.user.currentClient.id, locationName, currentAccountName)
    commit(SET_CURRENT_LOCATION, data)
  },
  updateLocation: async ({ commit, state, rootState }, { data, currentAccountName }) => {
    const response = await locationUpdate(
      rootState.user.currentClient.id,
      state.currentLocation.name,
      currentAccountName,
      data
    )
    commit(UPDATE_CURRENT_LOCATION, response.data)
  },
  loadServices: async ({ commit }, { regionCode, locale, categoryId }) => {
    const { data } = await getServicesFromCategory(regionCode, locale, categoryId)
    commit(SET_SERVICES, data.results)
  },
  loadCategories: async ({ commit }, { regionCode, view, locale, size, searchTerm = '', nextPageToken }) => {
    const params = {
      regionCode,
      locale,
      searchTerm,
      view,
      nextPageToken,
      size,
    }
    const { data, next_page_token } = await getCategories(params)
    commit(SET_CATEGORIES, {
      data: data.results,
      next: next_page_token
        ? {
            ...params,
            nextPageToken: next_page_token,
          }
        : null,
    })
  },
  loadMoreCategories: async ({ commit, state }) => {
    const { data, next_page_token: nextPageToken } = await getCategories(state.categories.next)
    commit(SET_CATEGORIES, {
      data: [...state.categories.data, ...data.results],
      next: { ...state.categories.next, nextPageToken },
    })
  },
  loadLocations: async ({ commit, rootState }, { accountName }) => {
    commit(RESET_LOCATIONS_GMB)
    const params = { clientId: rootState.user.currentClient.id, account: accountName }
    const { data, next_page_token: nextPageToken } = await locations(params)
    commit(SET_LOCATIONS_GMB, { data: data.results, next: { ...params, nextPageToken } })
  },
  loadMoreLocations: async ({ commit, state }) => {
    const { data, next_page_token: nextPageToken } = await locations(state.locationsGmb.next)
    commit(SET_LOCATIONS_GMB, {
      data: [...state.locationsGmb.data, ...data.results],
      next: { ...state.locationsGmb.next, nextPageToken },
    })
  },
  getAttributes: ({ commit }, { tableParams, countryCode, languageCode, categoryId }) => {
    const urlParams = tableOptions(tableParams, '', 'attributes')
    return attributes(countryCode, languageCode, categoryId, urlParams)
      .then(response => {
        commit(SET_ATTRIBUTES, response.data.results)
        delete response.data.results
        commit(SET_ATTRIBUTES_PAGINATION, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getChains: ({ commit }, query) => {
    return chains(query)
      .then(response => {
        commit(SET_CHAINS, response.data.results)
        delete response.data.results
        commit(SET_CHAINS_PAGINATION, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  clearStore: ({ commit }) => {
    commit(SET_CATEGORIES, {})
    commit(SET_ATTRIBUTES, {})
    commit(SET_CHAINS, [])
  },
  clearAttributes: ({ commit }) => {
    commit(SET_ATTRIBUTES, [])
  },
  getAccounts: ({ commit, rootState, state }, { tableParams, resetAccounts, search }) => {
    const urlParams = tableOptions(tableParams, search, 'accounts')
    return accounts(rootState.user.currentClient.id, urlParams)
      .then(response => {
        commit(SET_ACCOUNTS, response.data.results)
        commit(SET_ACCOUNTS_MOBILE, { accounts: response.data.results, reset: resetAccounts })
        delete response.data.results
        commit(SET_ACCOUNTS_PAGINATION, response.data)
        return Promise.resolve(state.accounts)
      })
      .catch(e => Promise.reject(e))
  },
  getFullAccounts: async ({ commit, state }, params) => {
    const urlParams = tableOptions(params, '', 'adminAccounts')
    const { data } = await getFullAccounts(urlParams)
    const { results, ...pagination } = data
    commit(SET_ADMIN_ACCOUNTS, results)
    commit(SET_ACCOUNTS_PAGINATION, pagination)
    return state.adminAccounts
  },
  getGeoJsons: async ({ commit }) => {
    const { features } = await geoJsons()
    commit(SET_WORLD_GEO_JSONS, features)
  },
  loadInvitations: async ({ commit }) => {
    const response = await listInvitations()
    commit(SET_INVITATIONS, response.data)
  },
  getExceptionnalHoursApi: async ({ commit }, { locale, year }) => {
    const response = await getExceptionnalHoursApi(locale, year)
    commit(SET_EXCEPTIONNAL_HOURS_API, response)
  },
}

const getters = {
  hasMoreQuestionAnswersToLoad: state => state.currentQuestionsAnswers?.next?.nextPageToken?.length > 0,
  hasMoreCategoriesToLoad: state => state.categories?.next?.nextPageToken?.length > 0,
  hasMoreLocationsToLoad: state => state.locationsGmb?.next?.nextPageToken?.length > 0,
  numberOfLoadedLocation: state =>
    typeof state.locationsGmb?.data?.length === 'number' ? state.locationsGmb?.data?.length : 0,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
