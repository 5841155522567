import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import store from '@/store'
import { isUserAuthorized, userRoles } from '@/config/permissions.config'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Backoffice.vue'),
    meta: {
      title: i18n.t('head.title.root'),
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'gmb',
        name: 'GMB',
        component: () => import('@/views/Gmb.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'qa/:name(accounts%2F[0-9]+)?',
            name: 'GmbQa',
            component: () => import('@/views/Gmb/Qa.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'gmb_qa',
            },
          },
          {
            path: 'qa/:name(accounts%2F[0-9]*%2Flocations%2F[0-9]*)/:currentTab(\\w+)?',
            name: 'GmbQaEdit',
            component: () => import('@/views/Gmb/QaEdit.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'gmb_qa',
            },
          },
          {
            path: 'admin/:currentTab(\\w+)?',
            name: 'AdminGmb',
            component: () => import('@/views/Gmb/Admin.vue'),
            meta: {
              productLine: 'admin',
              requiresAuth: true,
              navigation: 'gmb',
            },
          },
          {
            path: 'locations/:name(accounts%2F[0-9]+)?',
            name: 'LocationsGmb',
            component: () => import('@/views/Gmb/Locations.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'locations',
            },
          },
          {
            path: 'locations/:name(accounts%2F[0-9]*%2Flocations%2F[0-9]*)/:currentTab(\\w+)?',
            name: 'LocationGmb',
            component: () => import('@/views/Gmb/LocationsId.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'locations',
            },
          },
          {
            path: 'accounts',
            name: 'Accounts',
            component: () => import('@/views/Gmb/Accounts.vue'),
            meta: {
              productLine: 'influence',
              requiresAuth: true,
              navigation: 'gmb',
            },
          },
          {
            path: 'accounts/:name(accounts%2F[0-9]*)',
            name: 'AccountsId',
            component: () => import('@/views/Gmb/AccountsId.vue'),
            meta: {
              productLine: 'influence',
              requiresAuth: true,
              navigation: 'gmb',
            },
          },
        ],
      },
      {
        path: 'locations',
        component: () => import('@/views/Locations.vue'),
        children: [
          {
            path: 'lpe',
            name: 'Locations',
            component: () => import('@/views/Locations/List.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'locations',
            },
          },
          {
            path: 'create',
            name: 'LocationCreate',
            component: () => import('@/views/Locations/Create.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'locations',
              roles: [userRoles.superAdmin, userRoles.brandAdmin, userRoles.agency],
            },
          },
          {
            path: ':id([0-9]*)/:currentTab(\\w+)?',
            name: 'Location',
            component: () => import('@/views/Locations/Id.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'locations',
            },
          },
        ],
      },
      {
        path: 'user-express',
        component: () => import('@/views/UserExpress.vue'),
        children: [
          {
            path: 'list/:currentTab(\\w+)?',
            name: 'UserExpress',
            component: () => import('@/views/UserExpress/List.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'user_express',
            },
          },
          {
            path: 'create',
            name: 'UserExpressCreate',
            component: () => import('@/views/UserExpress/Create.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'user_express',
              roles: [userRoles.superAdmin, userRoles.brandAdmin, userRoles.agency, userRoles.groupAdmin],
            },
          },
          {
            path: ':id([0-9]*)/:currentTab(\\w+)?',
            name: 'UserExpressId',
            component: () => import('@/views/UserExpress/IdUser.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'user_express',
            },
          },
          {
            path: 'campaign/:id([0-9]*)/:currentTab(\\w+)?',
            name: 'CampaignId',
            component: () => import('@/views/UserExpress/IdCampaign.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'user_express',
            },
          },
        ],
      },
      {
        path: 'frontoffices',
        component: () => import('@/views/FrontOffices.vue'),
        children: [
          {
            path: '',
            name: 'FrontOffices',
            component: () => import('@/views/FrontOffices/List.vue'),
            meta: {
              productLine: 'influence',
              requiresAuth: true,
              navigation: 'locator',
            },
          },
          {
            path: 'create',
            name: 'FrontOfficeCreate',
            component: () => import('@/views/FrontOffices/Create.vue'),
            meta: {
              productLine: 'influence',
              requiresAuth: true,
              navigation: 'locator',
              roles: [userRoles.superAdmin],
            },
          },
          {
            path: ':id/:currentTab(\\w+)?',
            name: 'FrontOffice',
            component: () => import('@/views/FrontOffices/Id.vue'),
            meta: {
              productLine: 'influence',
              requiresAuth: true,
              navigation: 'locator',
            },
          },
        ],
      },
      {
        path: 'clients',
        component: () => import('@/views/Clients.vue'),
        children: [
          {
            path: '',
            name: 'Clients',
            component: () => import('@/views/Clients/List.vue'),
            meta: {
              productLine: 'admin',
              requiresAuth: true,
              navigation: 'clients',
            },
          },
          {
            path: 'create',
            name: 'ClientCreate',
            component: () => import('@/views/Clients/Create.vue'),
            meta: {
              productLine: 'admin',
              requiresAuth: true,
              navigation: 'clients',
              roles: [userRoles.superAdmin],
            },
          },
          {
            path: ':id/:currentTab(\\w+)?',
            name: 'Client',
            component: () => import('@/views/Clients/Id.vue'),
            meta: {
              productLine: 'admin',
              requiresAuth: true,
              navigation: 'clients',
            },
          },
        ],
      },
      {
        path: 'password',
        name: 'Password',
        component: () => import('@/views/Password.vue'),
        meta: {
          productLine: 'admin',
          requiresAuth: true,
          navigation: 'password',
        },
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('@/views/Settings.vue'),
        meta: {
          productLine: 'data',
          requiresAuth: true,
        },
      },
      {
        path: 'groups',
        name: 'Groups',
        component: () => import('@/views/Groups.vue'),
        meta: {
          productLine: 'data',
          requiresAuth: true,
          navigation: 'groups',
        },
      },
      {
        path: 'products',
        name: 'Products',
        component: () => import('@/views/Products.vue'),
        meta: {
          productLine: 'data',
          requiresAuth: true,
          navigation: 'products',
        },
      },
      {
        path: 'contacts',
        name: 'Contacts',
        component: () => import('@/views/Contacts.vue'),
        meta: {
          productLine: 'data',
          requiresAuth: true,
          navigation: 'contacts',
        },
      },
      {
        path: 'competition',
        name: 'Competition',
        component: () => import('@/views/Competition.vue'),
        meta: {
          productLine: 'data',
          requiresAuth: true,
          navigation: 'competition',
        },
      },
      {
        path: 'radar',
        name: 'Radar',
        component: () => import('@/views/Radar.vue'),
        meta: {
          productLine: 'measure',
          requiresAuth: true,
          navigation: 'radar',
        },
      },
      {
        path: 'analytics',
        name: 'Analytics',
        component: () => import('@/views/Analytics.vue'),
        meta: {
          productLine: 'measure',
          requiresAuth: true,
          navigation: 'analytics',
        },
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () => import('@/views/Reports.vue'),
        meta: {
          productLine: 'measure',
          requiresAuth: true,
          navigation: 'reports',
        },
      },
      {
        path: 'kpis',
        component: () => import('@/views/Kpis.vue'),
        meta: {
          productLine: 'measure',
          requiresAuth: true,
          navigation: 'kpis',
        },
        children: [
          {
            path: '',
            name: 'Kpis',
            component: () => import('@/views/Kpis/List.vue'),
            meta: {
              productLine: 'admin',
              requiresAuth: true,
              navigation: 'clients',
            },
          },
          {
            path: 'lpe_performance',
            name: 'LPEPerformance',
            component: () => import('@/views/Kpis/LPEPerformance.vue'),
            meta: {
              productLine: 'admin',
              requiresAuth: true,
              navigation: 'clients',
            },
          },
        ],
      },
      {
        path: 'play-collect',
        name: 'PlayCollect',
        component: () => import('@/views/PlayCollect.vue'),
        meta: {
          productLine: 'engage',
          requiresAuth: true,
          navigation: 'play_collect',
        },
      },
      {
        path: 'reponse-engine',
        name: 'ResponseEngine',
        component: () => import('@/views/ResponseEngine.vue'),
        meta: {
          productLine: 'engage',
          requiresAuth: true,
          navigation: 'response_engine',
        },
      },
      {
        path: 'qr-codes',
        name: 'QrCodes',
        component: () => import('@/views/QrCodes.vue'),
        meta: {
          productLine: 'engage',
          requiresAuth: true,
          navigation: 'qr_codes',
        },
      },
      {
        path: 'locator',
        name: 'Locator',
        component: () => import('@/views/Locator.vue'),
        meta: {
          productLine: 'influence',
          requiresAuth: true,
          navigation: 'locator',
        },
      },
      {
        path: 'presence-management',
        name: 'PresenceManagement',
        component: () => import('@/views/PresenceManagement.vue'),
        meta: {
          productLine: 'influence',
          requiresAuth: true,
          navigation: 'presence_management',
        },
      },
      {
        path: 'review-pages',
        name: 'ReviewPages',
        component: () => import('@/views/ReviewPages.vue'),
        meta: {
          productLine: 'influence',
          requiresAuth: true,
          navigation: 'review_pages',
        },
      },
      {
        path: 'leads',
        component: () => import('@/views/Leads.vue'),
        children: [
          {
            path: ':currentTab(\\w+)?',
            name: 'Leads',
            component: () => import('@/views/Leads/Leads.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'leads',
            },
          },
          {
            path: ':locationId/:id([0-9]*)/:currentTab(\\w+)?',
            name: 'Lead',
            component: () => import('@/views/Leads/Id.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'leads',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/views/Login.vue'),
    children: [
      {
        path: '',
        name: 'SignIn',
        component: () => import('@/components/Login/SignIn.vue'),
        meta: {
          title: `${i18n.t('head.title.root')} – ${i18n.t('login.signIn.title')}`,
          requiresAuth: false,
        },
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/components/Login/ForgotPassword.vue'),
        meta: {
          title: `${i18n.t('head.title.root')} – ${i18n.t('login.forgotPassword.title')}`,
          requiresAuth: false,
        },
      },
      {
        path: 'reset-password/:token',
        name: 'ResetPassword',
        component: () => import('@/components/Login/ResetPassword.vue'),
        meta: {
          title: `${i18n.t('head.title.root')} – ${i18n.t('login.resetPassword.title')}`,
          requiresAuth: false,
        },
      },
      {
        path: 'from_pc_bo',
        name: 'FromPcBo',
        component: () => import('@/components/Login/FromPcBo.vue'),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/components',
    name: 'Components',
    component: () => import('@/views/Components.vue'),
  },
  { path: '*', name: 'Error', component: () => import('@/views/Error.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const DEFAULT_TITLE = i18n.t('head.title.root')

const isAuthorized = (to, userState) =>
  !to.meta.requiresAuth || (userState.isLogged && isNavigationAccess(to, userState))

const isNavigationAccess = (to, userState) =>
  !to.meta.navigation ||
  (JSON.stringify(userState.navigation).indexOf(to.meta.navigation) !== -1 && isUserAuthorized(to.meta.roles))

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || DEFAULT_TITLE

  const userState = store.state.user
  const jwtToken = localStorage.getItem('jwt_token')
  const userToken = localStorage.getItem('user_token')

  if (to.name === 'SignIn' && userState.isLogged) {
    return next({ name: 'Home' })
  }

  if (isAuthorized(to, userState)) {
    return next()
  }

  if (jwtToken !== null && userToken !== null) {
    return store
      .dispatch('user/userNavigation')
      .then(() => {
        if (isNavigationAccess(to, userState)) {
          return next()
        }
        return next({ name: 'Home' })
      })
      .catch(() => store.dispatch('user/userLogout').then(() => next({ name: 'SignIn' })))
  }
  return next({ name: 'SignIn', query: { redirect: to.path } })
})

router.afterEach(() => {
  const userState = store.state.user
  const jwtToken = localStorage.getItem('jwt_token')
  const userToken = localStorage.getItem('user_token')

  if (jwtToken !== null && userToken !== null && userState.isLogged) {
    store.dispatch('user/userNotifications')
  }
})

export default router
