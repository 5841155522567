import { list } from '@/services/kpis.service'

const state = () => {
  return {
    list: [],
  }
}

const SET_KPIS = 'SET_KPIS'

const mutations = {
  [SET_KPIS]: (state, list) => {
    state.list = list
  },
}

const actions = {
  getKpisList: async ({ commit, rootState }) => {
    const kpis = await list(rootState.user.currentClient.id)
    commit(SET_KPIS, kpis)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
