import {
  clients,
  client,
  clientUpdate,
  clientCreate,
  clientFeatureList,
  clientFeatureListCreate,
  clientFeatureListUpdate,
  clientFeatureListDelete,
} from '@/services/client.service'
import { frontOffices, frontOfficeFeatureList } from '@/services/frontoffice.service'
import { tableOptions } from '@/utils/table.util'

const SET_CLIENTS = 'SET_CLIENTS'
const SET_CLIENTS_MOBILE = 'SET_CLIENTS_MOBILE'
const SET_CLIENTS_PAGINATION = 'SET_CLIENTS_PAGINATION'
const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT'
const UPDATE_CURRENT_CLIENT = 'UPDATE_CURRENT_CLIENT'
const SET_CURRENT_CLIENT_FEATURE_LIST = 'SET_CURRENT_CLIENT_FEATURE_LIST'
const CREATE_CURRENT_CLIENT_FEATURE_LIST = 'CREATE_CURRENT_CLIENT_FEATURE_LIST'
const UPDATE_CURRENT_CLIENT_FEATURE_LIST = 'UPDATE_CURRENT_CLIENT_FEATURE_LIST'
const DELETE_CURRENT_CLIENT_FEATURE_LIST = 'DELETE_CURRENT_CLIENT_FEATURE_LIST'

const state = () => {
  return {
    clients: [],
    clientsPagination: {},
    clientsMobile: [],
    currentClient: {},
    currentClientFeatureList: [],
  }
}

const mutations = {
  [SET_CLIENTS]: (state, clients) => {
    state.clients = clients
  },
  [SET_CLIENTS_MOBILE]: (state, { clients, reset }) => {
    if (reset) {
      state.clientsMobile = []
    }
    state.clientsMobile.push(...clients)
  },
  [SET_CLIENTS_PAGINATION]: (state, pagination) => {
    state.clientsPagination = pagination
  },
  [SET_CURRENT_CLIENT]: (state, client) => {
    state.currentClient = client
  },
  [UPDATE_CURRENT_CLIENT]: (state, updatedClient) => {
    state.currentClient = Object.assign(state.currentClient, updatedClient)
  },
  [SET_CURRENT_CLIENT_FEATURE_LIST]: (state, featureList) => {
    state.currentClientFeatureList = featureList
  },
  [CREATE_CURRENT_CLIENT_FEATURE_LIST]: (state, openingHour) => {
    state.currentClientFeatureList.push(openingHour)
  },
  [UPDATE_CURRENT_CLIENT_FEATURE_LIST]: (state, featureList) => {
    state.currentClientFeatureList = state.currentClientFeatureList.map(f => {
      if (f.id === featureList.id) {
        return featureList
      }
      return f
    })
  },
  [DELETE_CURRENT_CLIENT_FEATURE_LIST]: (state, featureList) => {
    state.currentClientFeatureList = state.currentClientFeatureList.filter(f => f.id !== featureList.id)
  },
}

const actions = {
  getClients: ({ commit }, { tableParams = null, resetClients, search }) => {
    const urlParams = tableOptions(tableParams, search, 'clients')
    return clients(urlParams)
      .then(response => {
        commit(SET_CLIENTS, response.data.results)
        commit(SET_CLIENTS_MOBILE, { clients: response.data.results, reset: resetClients })
        delete response.data.results
        commit(SET_CLIENTS_PAGINATION, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getClient: ({ commit }, { clientId }) => {
    return Promise.all([client(clientId), clientFeatureList(clientId)]).then(([client, features]) => {
      commit(SET_CURRENT_CLIENT, client.data)
      commit(SET_CURRENT_CLIENT_FEATURE_LIST, features.data.results)
      return Promise.resolve()
    })
  },
  updateClient: ({ commit, dispatch, state }, data) => {
    return clientUpdate(state.currentClient.id, data)
      .then(response => {
        commit(UPDATE_CURRENT_CLIENT, response.data)
        return dispatch('user/updateAllClients', null, { root: true })
      })
      .then(() => Promise.resolve())
  },
  getClientFeaturesList: async ({ commit }, { clientId }) => {
    try {
      let detailList = []
      const getClientFeaturesListFunc = clientFeatureList(clientId)
      const getFrontofficesFunc = frontOffices(clientId, '')
      const [featureList, frontOffice] = await Promise.all([getClientFeaturesListFunc, getFrontofficesFunc])

      if (frontOffice?.data?.results?.length > 0) {
        const { data } = await frontOfficeFeatureList(clientId, frontOffice.data.results[0].id)

        data.customListsGlobal
          .filter(itemCustomLists => itemCustomLists.locale === frontOffice.data.results[0].localeCode)
          .forEach(itemListDefault => (detailList = itemListDefault.lists))

        detailList.forEach(list => {
          featureList.data.results
            .filter(result => list.key === result.tag)
            .forEach(item => {
              item.items = list.items
              item.title = list.title
            })
        })
      }

      commit(SET_CURRENT_CLIENT_FEATURE_LIST, featureList.data.results)
    } catch {
      commit(SET_CURRENT_CLIENT_FEATURE_LIST, [])
    }
  },
  updateFeatureList: ({ state, commit, dispatch }, { featureList, status }) => {
    if (status === 'create') {
      return clientFeatureListCreate(state.currentClient.id, featureList)
        .then(response => {
          commit(CREATE_CURRENT_CLIENT_FEATURE_LIST, response.data)
          return dispatch('user/updateAllClients', null, { root: true })
        })
        .then(() => Promise.resolve())
    } else if (status === 'update') {
      return clientFeatureListUpdate(state.currentClient.id, featureList.id, featureList)
        .then(response => {
          commit(UPDATE_CURRENT_CLIENT_FEATURE_LIST, response.data)
          return dispatch('user/updateAllClients', null, { root: true })
        })
        .then(() => Promise.resolve())
    } else if (status === 'delete') {
      return clientFeatureListDelete(state.currentClient.id, featureList.id)
        .then(() => {
          commit(DELETE_CURRENT_CLIENT_FEATURE_LIST, featureList)
          return dispatch('user/updateAllClients', null, { root: true })
        })
        .then(() => Promise.resolve())
    }
  },
  createClient: (_, data) => {
    return clientCreate(data).then(response => {
      return Promise.resolve(response.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
