import { sendPutRequest, sendGetRequest, sendPostRequest } from '@/services/api.service'
import { API_USER } from '@/config/api.config'

export const login = (email, password, rememberMe) => {
  return sendPutRequest(`${API_USER.BASE_API_URL}${API_USER.LOGIN}`, {
    login: email,
    password,
    remember_me: rememberMe,
  })
}

export const forgetPassword = email => {
  return sendPostRequest(`${API_USER.BASE_API_URL}${API_USER.FORGET_PASSWORD}`, email)
}

export const checkToken = token => {
  return sendGetRequest(`${API_USER.BASE_API_URL}${API_USER.PASSWORD}?password_token=${token}`)
}

export const resetPassword = (password, password_token) => {
  return sendPostRequest(`${API_USER.BASE_API_URL}${API_USER.PASSWORD}`, {
    password,
    password_token,
  })
}

export const logout = () => {
  return sendGetRequest(`${API_USER.BASE_API_URL}${API_USER.LOGOUT}`)
}

export const nav = () => {
  return sendGetRequest(`${API_USER.BASE_API_URL}${API_USER.NAV}`)
}

export const getNotifications = () => {
  return sendGetRequest(`${API_USER.BASE_API_URL}${API_USER.NOTIFICATIONS}`)
}

export const postNotifications = notifications => {
  return sendPostRequest(`${API_USER.BASE_API_URL}${API_USER.NOTIFICATIONS}`, notifications)
}

export const updateSettings = data => {
  return sendPostRequest(`${API_USER.BASE_API_URL}${API_USER.UPDATE_SETTINGS}`, data)
}

export const updatePwd = data => {
  return sendPostRequest(`${API_USER.BASE_API_URL}${API_USER.UPDATE_PASSWORD}`, data)
}

export const gatewayCheck = data => {
  return sendPostRequest(`${API_USER.BASE_API_URL}${API_USER.GATEWAY_CHECK}`, data)
}
