import { sendGetRequest, sendPostRequest } from '@/services/api.service'
import { API_ALGOLIA } from '@/config/api.config'

export const publishing = (clientId, frontOfficeId) => {
  return sendGetRequest(
    `${API_ALGOLIA.BASE_API_URL}${API_ALGOLIA.LAUNCH_SYNCHRO}?clientId=${clientId}&id=${frontOfficeId}`
  )
}

export const getFrontOfficeSettings = (clientId, frontOfficeId) => {
  return sendGetRequest(`${API_ALGOLIA.BASE_API_URL}${API_ALGOLIA.SETTINGS}?clientId=${clientId}&id=${frontOfficeId}`)
}

export const updateFrontOfficeSettings = (clientId, frontOfficeId, data) => {
  return sendPostRequest(
    `${API_ALGOLIA.BASE_API_URL}${API_ALGOLIA.SETTINGS}?clientId=${clientId}&id=${frontOfficeId}`,
    data
  )
}
