import {
  login,
  logout,
  forgetPassword,
  nav,
  getNotifications,
  postNotifications,
  checkToken,
  resetPassword,
  updateSettings,
  updatePwd,
} from '@/services/auth.service'
import { clients, locales } from '@/services/client.service'
import { featureList } from '@/services/frontoffice.service'
import { countries } from '@/services/tool.service'
import i18n from '@/i18n'

const SET_IS_LOGGED = 'SET_IS_LOGGED'
const SET_CURRENT_USER = 'SET_CURRENT_USER'
const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT'
const SET_NAVIGATION = 'SET_NAVIGATION'
const SET_BRANDS = 'SET_BRANDS'
const SET_LOCALES = 'SET_LOCALES'
const SET_COUNTRIES = 'SET_COUNTRIES'
const SET_EXTERNAL_MODULES = 'SET_EXTERNAL_MODULES'
const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
const SET_EXTERNAL_LINKS_PROVIDER = 'SET_EXTERNAL_LINKS_PROVIDER'
const SET_LEAD_GENERATION_MODES = 'SET_LEAD_GENERATION_MODES'

const state = () => {
  return {
    logo: 'logo-rossignol.svg',
    account: {
      avatar: require('@/assets/img/account.png'),
      initials: 'XD',
    },
    isLogged: false,
    currentUser: null,
    navigation: [],
    brands: [],
    notifications: [],
    currentClient: {},
    locales: [],
    externalModules: null,
    countries: [],
  }
}

const mutations = {
  [SET_IS_LOGGED]: (state, boolean) => {
    state.isLogged = boolean
  },
  [SET_CURRENT_USER]: (state, user) => {
    state.currentUser = user
    i18n.locale = user.locale
  },
  [SET_CURRENT_CLIENT]: (state, { currentClient, featureList }) => {
    const client = {
      ...currentClient,
      featureList,
    }
    state.currentClient = client
    localStorage.setItem('client', JSON.stringify(client))
  },
  [SET_NAVIGATION]: (state, navigation) => {
    state.navigation = navigation
  },
  [SET_BRANDS]: (state, brands) => {
    state.brands = brands
  },
  [SET_LOCALES]: (state, locales) => {
    state.locales = locales
  },
  [SET_COUNTRIES]: (state, countries) => {
    state.countries = countries
  },
  [SET_EXTERNAL_MODULES]: (state, externalModules) => {
    state.externalModules = externalModules
  },
  [SET_EXTERNAL_LINKS_PROVIDER]: (state, externalLinksProvider) => {
    state.externalLinksProvider = externalLinksProvider
  },
  [SET_LEAD_GENERATION_MODES]: (state, leadGenerationModes) => {
    state.leadGenerationModes = leadGenerationModes
  },
  [SET_NOTIFICATIONS]: (state, { notifications, seen = false }) => {
    if (seen) {
      state.notifications = state.notifications.map(n => ({
        ...n,
        seen: true,
      }))
    } else {
      state.notifications = notifications
    }
  },
}

const actions = {
  userLogin: ({ dispatch }, { email, password, rememberMe }) => {
    return login(email, password, rememberMe)
      .then(response => {
        localStorage.setItem('jwt_token', response.data.jwt_token)
        localStorage.setItem('user_token', response.data.user_token)
        return dispatch('userNavigation')
      })
      .catch(e => Promise.reject(e))
  },
  userNavigation: ({ commit, dispatch, state }) => {
    return Promise.all([nav(), locales(), clients('')])
      .then(([navigation, locales, clients]) => {
        let currentClient = JSON.parse(localStorage.getItem('client'))
        if (currentClient) {
          currentClient = clients.data.results.find(client => client.id === currentClient.id)
        }
        commit(SET_CURRENT_USER, navigation.data.user)
        commit(SET_NAVIGATION, navigation.data.nav)
        commit(SET_EXTERNAL_MODULES, navigation.data.external_modules)
        commit(SET_EXTERNAL_LINKS_PROVIDER, navigation.data.external_links_provider)
        commit(SET_LEAD_GENERATION_MODES, navigation.data.lead_generation_modes)
        commit(SET_BRANDS, clients.data.results)
        commit(SET_LOCALES, locales.data.results)
        commit(SET_IS_LOGGED, true)
        return Promise.all([
          dispatch('getCountries', state.currentUser.locale),
          dispatch('updateCurrentClient', currentClient || clients.data.results[0]),
        ])
      })
      .then(() => Promise.resolve())
      .catch(e => Promise.reject(e))
  },
  userForgetPassword: (_, email) => {
    return forgetPassword(email)
      .then(response => Promise.resolve(response.data.token))
      .catch(() => Promise.reject())
  },
  userCheckPasswordToken: (_, token) => {
    return checkToken(token)
      .then(response => Promise.resolve(response.data.email))
      .catch(() => Promise.reject())
  },
  userResetPassword: (_, { password, token }) => {
    return resetPassword(password, token)
      .then(() => Promise.resolve())
      .catch(() => Promise.reject())
  },
  userLogout: ({ commit }) => {
    return logout()
      .finally(() => {
        localStorage.removeItem('jwt_token')
        localStorage.removeItem('user_token')
        commit(SET_IS_LOGGED, false)
        return Promise.resolve()
      })
      .catch(() => false)
  },
  userNotifications: ({ commit }) => {
    return getNotifications()
      .then(response => {
        commit(SET_NOTIFICATIONS, { notifications: response.data })
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  sendNotifications: ({ state, commit }) => {
    return postNotifications(state.notifications)
      .then(() => {
        commit(SET_NOTIFICATIONS, { seen: true })
      })
      .catch(() => false)
  },
  updateAllClients: ({ commit, dispatch }) => {
    return clients('')
      .then(clients => {
        let currentClient = JSON.parse(localStorage.getItem('client'))
        if (currentClient) {
          currentClient = clients.data.results.find(client => client.id === currentClient.id)
        }
        commit(SET_BRANDS, clients.data.results)
        return dispatch('updateCurrentClient', currentClient || clients.data.results[0])
      })
      .then(() => Promise.resolve())
      .catch(e => Promise.reject(e))
  },
  updateCurrentClient: ({ commit, dispatch }, currentClient) => {
    if (currentClient) {
      return featureList(currentClient.id).then(response => {
        commit(SET_CURRENT_CLIENT, { currentClient, featureList: response.data.results })
        localStorage.removeItem('frontoffice')
        return Promise.resolve()
      })
    }
    dispatch('backoffice/closeEverything', { brands: true }, { root: true })
  },
  getCountries: ({ commit }, locale) => {
    return countries(locale)
      .then(response => {
        commit(SET_COUNTRIES, response.data.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  updateUser: ({ commit, state }, data) => {
    return updateSettings(data)
      .then(response => {
        commit(SET_CURRENT_USER, { ...state.currentUser, ...response.data })
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  updatePassword: (_, data) => {
    return updatePwd(data)
      .then(() => Promise.resolve())
      .catch(e => Promise.reject(e))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
