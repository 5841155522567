import { sendPostRequest, sendGetRequest } from '@/services/api.service'
import { API_TOOLS } from '@/config/api.config'

export const uploadMedia = (clientId, data, frontOfficeId = null) => {
  if (frontOfficeId !== null) {
    return sendPostRequest(
      `${API_TOOLS.BASE_API_URL}${API_TOOLS.UPLOAD_MEDIA}?clientId=${clientId}&frontOfficeId=${frontOfficeId}`,
      data
    )
  }
  return sendPostRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.UPLOAD_MEDIA}?clientId=${clientId}`, data)
}

export const cloneMedia = (clientId, data) => {
  return sendPostRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.CLONE_MEDIA}?clientId=${clientId}`, data)
}

export const removeMedia = (clientId, data) => {
  return sendPostRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.REMOVE_MEDIA}?clientId=${clientId}`, data)
}

export const geocode = address => {
  return sendGetRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.GEOCODE}?address=${address}`)
}

export const getGeonames = data => {
  return sendPostRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.GEONAME}`, data)
}

export const getInternationalPhone = data => {
  return sendPostRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.INTERNATIONAL_PHONE}`, data)
}

export const countries = (locale = 'en') => {
  return sendGetRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.COUNTRIES}?locale=${locale}`)
}

export const subDivisions = (locale = 'en', countryCode) => {
  return sendGetRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.SUB_DIVISIONS}?locale=${locale}&country=${countryCode}`)
}
