import { sendGetRequest, sendPostRequest, sendPutRequest, sendDeleteRequest } from '@/services/api.service'
import { API_GMB } from '@/config/api.config'

export const geoJsons = () => {
  return sendGetRequest('/countries.geojson')
}

export const getCategories = ({ regionCode, locale, searchTerm, size, view = 'basic', nextPageToken }) => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.CATEGORIES}`, {
    params: {
      regionCode,
      locale,
      searchTerm,
      view,
      nextPageToken,
      size,
    },
  })
}

export const attributes = (countryCode, languageCode, categoryId, urlParams) => {
  return sendGetRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.ATTRIBUTES}?regionCode=${countryCode}&locale=${languageCode}&gcid=${categoryId}${urlParams}`
  )
}

export const chains = query => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.CHAINS}?query=${query}`)
}

export const accounts = (clientId, urlParams) => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.ACCOUNTS}?clientId=${clientId}${urlParams}`)
}

export const getFullAccounts = urlParams => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.ACCOUNTS_FULL}${urlParams.replace('&', '?')}`)
}

export const getAccountNotifications = (clientId, account) => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.ACCOUNT}`, {
    params: {
      clientId,
      account,
    },
  })
}

export const activateAndGetNotifications = (clientId, account) => {
  return sendPostRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.ACCOUNT_NOTIFICATION}`,
    {},
    {
      params: {
        account,
        clientId,
      },
    }
  )
}

export const listInvitations = () => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.INVITATIONS}`)
}

export const sendInvitation = (account, brandId) => {
  return sendPostRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.INVITATIONS}`,
    {},
    {
      params: {
        account,
        brandId,
      },
    }
  )
}

export const locations = ({ clientId, account, nextPageToken }) => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.LOCATIONS}`, {
    params: {
      clientId,
      account,
      nextPageToken,
    },
  })
}

export const location = (clientId, locationName, account) => {
  return sendGetRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.LOCATION}?clientId=${clientId}&id=${locationName}&account=${account}`
  )
}

export const locationUpdate = (clientId, locationName, account, data) => {
  return sendPostRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.LOCATION}?clientId=${clientId}&id=${locationName}&account=${account}`,
    data
  )
}

export const getServicesFromCategory = (regionCode, locale, categoryId) => {
  return sendGetRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.SERVICES}?regionCode=${regionCode}&locale=${locale}&categoryId=${categoryId}`
  )
}

export const locationMedias = (clientId, locationName, account) => {
  return sendGetRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.MEDIA}?clientId=${clientId}&id=${locationName}&account=${account}`
  )
}

export const getExceptionnalHoursApi = (locale, year) => {
  return sendGetRequest(`${API_GMB.HOLIDAYS}country=${locale}&year=${year}`)
}

export const recordFlagEvent = (clientId, locationName, account) => {
  return sendPutRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.MEDIA_CUSTOMER}`,
    {},
    {
      params: {
        clientId,
        id: locationName,
        account,
      },
    }
  )
}

export const deleteLocationMedia = (clientId, account, id) => {
  return sendDeleteRequest(`${API_GMB.BASE_API_URL}${API_GMB.MEDIA_ID}`, {
    params: {
      clientId,
      id,
      account,
    },
  })
}

export const importPicture = ({ clientId, account, locationName, mediaCategory, name, file }) => {
  return sendPostRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.MEDIA_ID}`,
    { target: mediaCategory, name, file },
    { params: { clientId, id: locationName, account } }
  )
}
