<template>
  <div v-if="visible" class="modal">
    <transition
      name="fade"
      @before-enter="beforeModalTransitionEnter"
      @after-enter="afterModalTransitionEnter"
      @before-leave="beforeModalTransitionLeave"
      @after-leave="afterModalTransitionLeave"
    >
      <div
        class="modal__wrapper"
        v-if="visibility.modal"
        ref="modal"
        role="dialog"
        :aria-expanded="visibility.modal.toString()"
        aria-modal="true"
      >
        <div
          v-if="visibility.overlay"
          @click.stop="onOverlayClick"
          class="modal__wrapper__overlay"
          :class="{ 'modal__wrapper__overlay--transparent': hideOverlay }"
          :aria-expanded="visibility.overlay.toString()"
        ></div>
        <div class="modal__wrapper__box" :style="{ width: `${width}px`, height }">
          <header class="modal__wrapper__box__header" v-if="title !== ''">
            <ui-button
              class="modal__wrapper__box__header__close"
              @click="cancel"
              button="secondary"
              :label="$t('common.button.close')"
              :icon-only="true"
              icon="close"
            />
            <div class="modal__wrapper__box__header__title" v-html="title"></div>
          </header>
          <div class="modal__wrapper__box__container">
            <slot name="container"></slot>
          </div>
          <footer class="modal__wrapper__box__footer" v-if="!isDeleting">
            <slot name="actions">
              <ui-button
                v-if="hasClose"
                class="modal__wrapper__box__footer__close"
                :label="cancelLabel || $t('common.button.cancel')"
                button="secondary"
                @click.prevent="cancel"
              />
              <ui-button
                v-if="hasDelete"
                class="modal__wrapper__box__footer__apply"
                :label="$t('common.button.delete')"
                button="cta"
                variant="error"
                icon="delete"
                :icon-only="true"
                :disabled="isUpdating"
                @click="askForDelete"
                v-tooltip="{
                  placement: 'top',
                  trigger: 'hover',
                  content: deleteTooltip,
                  offset: 3,
                }"
              />
              <ui-button
                v-if="hasApply"
                class="modal__wrapper__box__footer__apply"
                :label="applyLabel || $t('common.button.apply')"
                button="cta"
                :variant="applyVariant"
                :disabled="isUpdating"
                @click.prevent="apply"
              />
            </slot>
          </footer>
          <footer class="modal__wrapper__box__deletion" v-else>
            <div class="modal__wrapper__box__deletion__label">
              {{ deleteLabel }}
            </div>
            <div class="modal__wrapper__box__deletion__actions">
              <ui-button
                class="modal__wrapper__box__deletion__actions__cancel"
                :label="$t('common.button.no')"
                button="primary"
                variant="error"
                icon="close"
                :icon-only="true"
                @click.prevent="cancelDelete"
              />
              <ui-button
                class="modal__wrapper__box__deletion__actions__delete"
                :label="$t('common.button.yes')"
                button="primary"
                variant="success"
                icon="check"
                :icon-only="true"
                @click.prevent="remove"
                v-if="hasApply"
              />
            </div>
          </footer>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { blurActiveElement } from '@/utils/blur.util'
import UiButton from '@/components/UI/Button.vue'

const TransitionState = {
  Enter: 'enter',
  Entering: 'entering',
  Leave: 'leave',
  Leaving: 'leaving',
}

export default {
  name: 'Modal',
  components: {
    UiButton,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    hasClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasApply: {
      type: Boolean,
      required: false,
      default: false,
    },
    applyLabel: {
      type: String,
      required: false,
      default: '',
    },
    applyVariant: {
      type: String,
      required: false,
      default: 'success',
    },
    cancelLabel: {
      type: String,
      required: false,
      default: '',
    },
    hasDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: [Number, String],
      required: false,
      default: 700,
    },
    height: {
      type: String,
      required: false,
      default: '',
    },
    clickToClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    deleteLabel: {
      type: String,
      required: false,
      default: '',
    },
    deleteTooltip: {
      type: String,
      required: false,
      default: '',
    },
    hideOverlay: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      visibility: {
        modal: false,
        overlay: false,
      },
      overlayTransitionState: null,
      modalTransitionState: null,
      isDeleting: false,
    }
  },
  /**
   * Sets global listeners
   */
  beforeMount() {
    this.$modal.subscription.$on('toggle', this.onToggle)

    if (this.clickToClose) {
      window.addEventListener('keyup', this.onEscapeKeyUp)
    }
  },
  /**
   * Removes global listeners
   */
  beforeDestroy() {
    this.$modal.subscription.$off('toggle', this.onToggle)

    if (this.clickToClose) {
      window.removeEventListener('keyup', this.onEscapeKeyUp)
    }
  },
  watch: {
    isComponentReadyToBeDestroyed(isReady) {
      if (isReady) {
        this.visible = false
      }
    },
  },
  computed: {
    isComponentReadyToBeDestroyed() {
      return this.modalTransitionState === TransitionState.Leave
    },
  },
  methods: {
    startTransitionEnter() {
      this.visibility.overlay = true
      this.visibility.modal = true
    },

    startTransitionLeave() {
      this.visibility.overlay = false
      this.visibility.modal = false
    },

    beforeModalTransitionEnter() {
      this.modalTransitionState = TransitionState.Entering
    },

    beforeModalTransitionLeave() {
      this.modalTransitionState = TransitionState.Leaving
    },

    afterModalTransitionEnter() {
      this.modalTransitionState = TransitionState.Enter
      this.$emit('opened', event)
    },

    afterModalTransitionLeave() {
      this.modalTransitionState = TransitionState.Leave
      this.$emit('closed', event)
    },

    onToggle(name, state, params) {
      if (this.name === name) {
        const nextState = typeof state === 'undefined' ? !this.visible : state

        this.toggle(nextState, params)
      }
    },

    onEscapeKeyUp(event) {
      if (event.which === 27 && this.visible) {
        this.$modal.hide(this.name)
      }
    },
    open(params) {
      this.$emit('before-open', params)
      /**
       * Need to unfocus previously focused element, otherwise
       * all keypress events (ESC press, for example) will trigger on that element.
       */
      blurActiveElement()

      this.visible = true
      /* Making sure that entering tranition uses "enter" sequance instead of "appear" */
      this.$nextTick(() => {
        this.startTransitionEnter()
      })
    },
    cancel() {
      this.$emit('cancel')
      this.close()
    },
    close() {
      this.isDeleting = false
      this.$emit('before-close', event)
      this.startTransitionLeave()
    },
    apply() {
      this.$emit('save')
    },
    remove() {
      this.$emit('remove')
      this.isDeleting = false
    },
    cancelDelete() {
      this.isDeleting = false
    },
    askForDelete() {
      this.isDeleting = true
    },
    pendingOver() {
      this.$emit('stop')
    },

    toggle(isOpening, params) {
      const { visible } = this

      if (visible === isOpening) {
        return
      }

      if (isOpening) {
        this.open(params)
      } else {
        this.close(params)
      }
    },

    /**
     * Event handler that is triggered when background overlay is clicked
     */
    onOverlayClick() {
      this.$emit('cancel')
      if (this.clickToClose) {
        this.toggle(false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    @include modal-wrapper($justify: center, $zIndex: 100, $align: center);

    &__overlay {
      @include overlay;

      &--transparent {
        background: transparent;
      }
    }

    &__box {
      @include modal-box(101);

      &__header {
        @include header;

        &__close {
          @include close;
        }

        &__title {
          @include title(true, right);
        }
      }

      &__container {
        @include modal-container;
      }

      &__footer {
        @include footer;
      }

      &__deletion {
        @include footer(space-between);

        &__label {
          flex: 1;
        }

        &__actions {
          &__delete {
            margin-left: $gutter-mobile;

            @media (min-width: $screen-sm) {
              margin-left: $gutter-tablet;
            }
          }
        }
      }
    }
  }
}
</style>
