const createSearchQueryFromSearch = (search, view) => {
  if (!(search && search.length > 0)) {
    return ''
  }
  const cleanSearch = search.trim()
  switch (view) {
    case 'locations':
      return `postalCode:${cleanSearch};name:${cleanSearch};externalId:${cleanSearch};city:${cleanSearch}`

    case 'clients':
    case 'frontOffices':
      return `name:${cleanSearch}`
    case 'leads':
      return `productId:${cleanSearch};productName:${cleanSearch}`
    case 'userExpress':
      return `userExpressId:${cleanSearch}`
    default:
      return ''
  }
}

const createSortQueryFromSortTableParam = sort =>
  sort && sort.field && sort.type && sort.type !== 'NONE' ? `${sort.field},${sort.type}` : ''

export const tableOptions = (tableParams, search, view) => {
  const options = tableParams || {}
  const urlParams = {
    size: options.size,
    page: options.page,
    sort: createSortQueryFromSortTableParam(options.sort),
    search: [createSearchQueryFromSearch(search, view), options.filters].filter(v => v && v.length > 0).join(','),
    mode: options.mode,
    ue: options.ue,
  }
  return (
    '&' +
    Object.entries(urlParams)
      .filter(([, urlParamValue]) => urlParamValue)
      .map(([urlParamName, urlParamValue]) => `${urlParamName}=${urlParamValue}`)
      .join('&')
  )
}
