import {
  locations,
  location,
  locationCreate,
  locationUpdate,
  locationMapping,
  locationPostMapping,
  locationOpeningHours,
  locationOpeningHourCreate,
  locationOpeningHourUpdate,
  locationOpeningHourDelete,
  locationExceptionalOpeningHours,
  locationExceptionalOpeningHourCreate,
  locationExceptionalOpeningHourUpdate,
  locationExceptionalOpeningHourDelete,
  locationUrls,
  locationUrlCreate,
  locationUrlUpdate,
  locationUrlDelete,
  locationExternalIdInfo,
} from '@/services/location.service'

import { tableOptions } from '@/utils/table.util'

const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION'
const UPDATE_CURRENT_LOCATION = 'UPDATE_CURRENT_LOCATION'
const SET_LOCATIONS = 'SET_LOCATIONS'
const SET_LOCATIONS_MOBILE = 'SET_LOCATIONS_MOBILE'
const SET_LOCATIONS_PAGINATION = 'SET_LOCATIONS_PAGINATION'
const SET_CURRENT_LOCATION_OPENING_HOURS = 'SET_CURRENT_LOCATION_OPENING_HOURS'
const SET_CURRENT_LOCATION_URLS = 'SET_CURRENT_LOCATION_URLS'
const SET_CURRENT_LOCATION_MAPPING = 'SET_CURRENT_LOCATION_MAPPING'
const CREATE_CURRENT_LOCATION_URLS = 'CREATE_CURRENT_LOCATION_URLS'
const UPDATE_CURRENT_LOCATION_URLS = 'UPDATE_CURRENT_LOCATION_URLS'
const DELETE_CURRENT_LOCATION_URLS = 'DELETE_CURRENT_LOCATION_URLS'
const CREATE_CURRENT_LOCATION_OPENING_HOURS = 'CREATE_CURRENT_LOCATION_OPENING_HOURS'
const UPDATE_CURRENT_LOCATION_OPENING_HOURS = 'UPDATE_CURRENT_LOCATION_OPENING_HOURS'
const DELETE_CURRENT_LOCATION_OPENING_HOURS = 'DELETE_CURRENT_LOCATION_OPENING_HOURS'
const SET_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS = 'SET_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS'
const CREATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS = 'CREATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS'
const UPDATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS = 'UPDATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS'
const DELETE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS = 'DELETE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS'

const state = () => {
  return {
    currentLocation: {},
    currentLocationMapping: [],
    currentLocationUrls: [],
    currentLocationOpeningHours: [],
    currentLocationExceptionalOpeningHours: [],
    locations: [],
    locationsMobile: [],
    locationsPagination: {},
  }
}

const mutations = {
  [SET_LOCATIONS]: (state, locations) => {
    state.locations = locations
  },
  [SET_CURRENT_LOCATION_MAPPING]: (state, locations) => {
    state.currentLocationMapping = locations || []
  },
  [SET_LOCATIONS_MOBILE]: (state, { locations, reset }) => {
    if (reset) {
      state.locationsMobile = []
    }
    state.locationsMobile.push(...locations)
  },
  [SET_CURRENT_LOCATION]: (state, location) => {
    state.currentLocation = location
  },
  [UPDATE_CURRENT_LOCATION]: (state, updatedLocation) => {
    state.currentLocation = Object.assign(state.currentLocation, updatedLocation)
  },
  [SET_LOCATIONS_PAGINATION]: (state, pagination) => {
    state.locationsPagination = pagination
  },
  [SET_CURRENT_LOCATION_URLS]: (state, urls) => {
    state.currentLocationUrls = urls
  },
  [CREATE_CURRENT_LOCATION_URLS]: (state, url) => {
    state.currentLocationUrls.push(url)
  },
  [UPDATE_CURRENT_LOCATION_URLS]: (state, url) => {
    state.currentLocationUrls = state.currentLocationUrls.map(u => {
      if (u.id === url.id) {
        return url
      }
      return u
    })
  },
  [DELETE_CURRENT_LOCATION_URLS]: (state, url) => {
    state.currentLocationUrls = state.currentLocationUrls.filter(u => u.id !== url.id)
  },
  [SET_CURRENT_LOCATION_OPENING_HOURS]: (state, openingHours) => {
    state.currentLocationOpeningHours = openingHours
  },
  [CREATE_CURRENT_LOCATION_OPENING_HOURS]: (state, openingHour) => {
    state.currentLocationOpeningHours.push(openingHour)
  },
  [UPDATE_CURRENT_LOCATION_OPENING_HOURS]: (state, openingHours) => {
    state.currentLocationOpeningHours = state.currentLocationOpeningHours.map(o => {
      if (o.id === openingHours.id) {
        return openingHours
      }
      return o
    })
  },
  [DELETE_CURRENT_LOCATION_OPENING_HOURS]: (state, openingHours) => {
    state.currentLocationOpeningHours = state.currentLocationOpeningHours.filter(o => o.id !== openingHours.id)
  },
  [SET_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS]: (state, exceptionalOpeningHours) => {
    state.currentLocationExceptionalOpeningHours = exceptionalOpeningHours
  },
  [CREATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS]: (state, exceptionalOpeningHour) => {
    state.currentLocationExceptionalOpeningHours.push(exceptionalOpeningHour)
  },
  [UPDATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS]: (state, exceptionalOpeningHours) => {
    state.currentLocationExceptionalOpeningHours = state.currentLocationExceptionalOpeningHours.map(eo => {
      if (eo.id === exceptionalOpeningHours.id) {
        return exceptionalOpeningHours
      }
      return eo
    })
  },
  [DELETE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS]: (state, exceptionalOpeningHours) => {
    state.currentLocationExceptionalOpeningHours = state.currentLocationExceptionalOpeningHours.filter(
      eo => eo.id !== exceptionalOpeningHours.id
    )
  },
}

const actions = {
  getLocations: ({ commit, rootState }, { tableParams, resetLocations, search }) => {
    const urlParams = tableOptions(tableParams, search, 'locations')
    return locations(rootState.user.currentClient.id, urlParams)
      .then(response => {
        commit(SET_LOCATIONS, response.data.results)
        commit(SET_LOCATIONS_MOBILE, { locations: response.data.results, reset: resetLocations })
        delete response.data.results
        commit(SET_LOCATIONS_PAGINATION, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getLocation: ({ commit, rootState, state, dispatch }, { locationId }) => {
    const hasFrontOffice = !!rootState.frontoffice?.currentFrontOffice?.localeId
    const localeId = hasFrontOffice
      ? rootState.frontoffice.currentFrontOffice.localeId
      : rootState.user.currentClient.defaultLocaleId
    const isDefaultLocaleId = hasFrontOffice
      ? localeId === rootState.frontoffice.currentFrontOffice.defaultLocaleId
      : true
    return Promise.all([
      location({
        clientId: rootState.user.currentClient.id,
        id: locationId,
        frontOfficeId: hasFrontOffice ? rootState.frontoffice.currentFrontOffice.id : null,
        localeId,
        isDefaultLocaleId,
      }),
      locationOpeningHours(rootState.user.currentClient.id, locationId),
      locationExceptionalOpeningHours(rootState.user.currentClient.id, locationId),
      locationUrls(rootState.user.currentClient.id, locationId),
    ])
      .then(([location, openingHours, exceptionalOpeningHours, urls]) => {
        commit(SET_CURRENT_LOCATION, location.data)
        commit(SET_CURRENT_LOCATION_OPENING_HOURS, openingHours.data.results)
        commit(SET_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS, exceptionalOpeningHours.data.results)
        commit(SET_CURRENT_LOCATION_URLS, urls.data.results)
        return dispatch(
          'tool/getSubDivisions',
          { locale: rootState.user.currentUser.locale, countryCode: state.currentLocation.countryCode },
          { root: true }
        )
      })
      .then(() => Promise.resolve())
  },
  getCurrentLocationsMapping: async ({ commit }, { id, accountGmb, account }) => {
    const { data } = await locationMapping(id, accountGmb, account)
    commit(SET_CURRENT_LOCATION_MAPPING, data)
    return Promise.resolve()
  },
  updateCurrentLocationsMapping: async ({ commit }, { id, accountGmb, account, placeId, locationsId }) => {
    const { data } = await locationPostMapping(id, accountGmb, account, placeId, locationsId)
    commit(SET_CURRENT_LOCATION_MAPPING, data)
    return Promise.resolve()
  },
  updateLocation: async ({ commit, state, rootState, dispatch }, data) => {
    const hasFrontOffice = !!rootState.frontoffice?.currentFrontOffice?.localeId
    const localeId = hasFrontOffice
      ? rootState.frontoffice.currentFrontOffice.localeId
      : rootState.user.currentClient.defaultLocaleId
    const isDefaultLocaleId = hasFrontOffice
      ? localeId === rootState.frontoffice.currentFrontOffice.defaultLocaleId
      : true
    const { data: newCurrentLocation } = await locationUpdate(
      {
        clientId: rootState.user.currentClient.id,
        id: state.currentLocation.id,
        frontOfficeId: hasFrontOffice ? rootState.frontoffice.currentFrontOffice.id : null,
        localeId,
        isDefaultLocaleId,
      },
      data
    )
    commit(UPDATE_CURRENT_LOCATION, newCurrentLocation)
    if (data.countryCodeChanged) {
      return dispatch(
        'tool/getSubDivisions',
        { locale: rootState.user.currentUser.locale, countryCode: state.currentLocation.countryCode },
        { root: true }
      )
    }
  },
  updateUrl: ({ state, commit, rootState }, { url, status }) => {
    if (status === 'create') {
      return locationUrlCreate(rootState.user.currentClient.id, state.currentLocation.id, url).then(response => {
        commit(CREATE_CURRENT_LOCATION_URLS, response.data)
        return Promise.resolve()
      })
    } else if (status === 'update') {
      return locationUrlUpdate(rootState.user.currentClient.id, state.currentLocation.id, url.id, url).then(
        response => {
          commit(UPDATE_CURRENT_LOCATION_URLS, response.data)
          return Promise.resolve()
        }
      )
    } else if (status === 'delete') {
      return locationUrlDelete(rootState.user.currentClient.id, state.currentLocation.id, url.id).then(() => {
        commit(DELETE_CURRENT_LOCATION_URLS, url)
        return Promise.resolve()
      })
    }
  },
  updateOpeningHour: ({ state, commit, rootState }, { openingHour, status, period }) => {
    if (status === 'create') {
      return locationOpeningHourCreate(
        rootState.user.currentClient.id,
        state.currentLocation.id,
        openingHour,
        period
      ).then(response => {
        commit(CREATE_CURRENT_LOCATION_OPENING_HOURS, response.data)
        return Promise.resolve()
      })
    } else if (status === 'update') {
      return locationOpeningHourUpdate(
        rootState.user.currentClient.id,
        state.currentLocation.id,
        openingHour.id,
        openingHour
      ).then(response => {
        commit(UPDATE_CURRENT_LOCATION_OPENING_HOURS, response.data)
        return Promise.resolve()
      })
    } else if (status === 'delete') {
      return locationOpeningHourDelete(rootState.user.currentClient.id, state.currentLocation.id, openingHour.id).then(
        () => {
          commit(DELETE_CURRENT_LOCATION_OPENING_HOURS, openingHour)
          return Promise.resolve()
        }
      )
    }
  },
  updateExceptionalOpeningHour: ({ state, commit, rootState }, { exceptionalOpeningHour, status }) => {
    if (status === 'create') {
      return locationExceptionalOpeningHourCreate(
        rootState.user.currentClient.id,
        state.currentLocation.id,
        exceptionalOpeningHour
      ).then(response => {
        commit(CREATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS, response.data)
        return Promise.resolve()
      })
    } else if (status === 'update') {
      return locationExceptionalOpeningHourUpdate(
        rootState.user.currentClient.id,
        state.currentLocation.id,
        exceptionalOpeningHour.id,
        exceptionalOpeningHour
      ).then(response => {
        commit(UPDATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS, response.data)
        return Promise.resolve()
      })
    } else if (status === 'delete') {
      return locationExceptionalOpeningHourDelete(
        rootState.user.currentClient.id,
        state.currentLocation.id,
        exceptionalOpeningHour.id
      ).then(() => {
        commit(DELETE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS, exceptionalOpeningHour)
        return Promise.resolve()
      })
    }
  },
  getOpeningHour: ({ commit, rootState }, locationId) => {
    locationOpeningHours(rootState.user.currentClient.id, locationId).then(response => {
      commit(SET_CURRENT_LOCATION_OPENING_HOURS, response.data.results)
    })
  },
  getExceptionalOpeningHour: ({ commit, rootState }, { period, locationId }) => {
    locationExceptionalOpeningHours(rootState.user.currentClient.id, locationId, period).then(response => {
      commit(SET_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS, response.data.results)
    })
  },
  createLocation: (_, data) => {
    return locationCreate(data.clientId, data).then(response => {
      return Promise.resolve(response.data)
    })
  },
  getUniqueExternalId: async ({ rootState }, externalId) => {
    const { data } = await locationExternalIdInfo(rootState.user.currentClient.id, externalId)
    return data.results.length > 0
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
