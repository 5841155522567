import { leads, lead, leadHistory, graph, overview, summary, network } from '@/services/lead.service'
import { tableOptions } from '@/utils/table.util'

const SET_LEADS = 'SET_LEADS'
const SET_LEADS_MOBILE = 'SET_LEADS_MOBILE'
const SET_LEADS_PAGINATION = 'SET_LEADS_PAGINATION'
const SET_CURRENT_LEAD = 'SET_CURRENT_LEAD'
const SET_CURRENT_LEAD_HISTORY = 'SET_CURRENT_LEAD_HISTORY'
const SET_DASHBOARD_GRAPH = 'SET_DASHBOARD_GRAPH'
const SET_DASHBOARD_SUMMARY = 'SET_DASHBOARD_SUMMARY'
const SET_DASHBOARD_OVERVIEW = 'SET_DASHBOARD_OVERVIEW'
const SET_DASHBOARD_NETWORK = 'SET_DASHBOARD_NETWORK'

const state = () => {
  return {
    leads: [],
    leadsMobile: [],
    leadsPagination: {},
    currentLead: {},
    currentLeadHistory: [],
    dashboardGraph: {},
    dashboardSummary: {},
    dashboardOverview: {},
    dashboardNetwork: {},
  }
}

const mutations = {
  [SET_LEADS]: (state, leads) => {
    state.leads = leads
  },
  [SET_LEADS_MOBILE]: (state, { leads, reset }) => {
    if (reset) {
      state.leadsMobile = []
    }
    state.leadsMobile.push(...leads)
  },
  [SET_LEADS_PAGINATION]: (state, leads) => {
    state.leadsPagination = leads
  },
  [SET_CURRENT_LEAD]: (state, lead) => {
    state.currentLead = lead
  },
  [SET_CURRENT_LEAD_HISTORY]: (state, lead) => {
    state.currentLeadHistory = lead
  },
  [SET_DASHBOARD_GRAPH]: (state, graph) => {
    state.dashboardGraph = graph
  },
  [SET_DASHBOARD_SUMMARY]: (state, summary) => {
    state.dashboardSummary = summary
  },
  [SET_DASHBOARD_OVERVIEW]: (state, overview) => {
    state.dashboardOverview = overview
  },
  [SET_DASHBOARD_NETWORK]: (state, network) => {
    state.dashboardNetwork = network
  },
}

const actions = {
  getLeads: ({ commit, rootState }, { tableParams, resetLeads, search }) => {
    const urlParams = tableOptions(tableParams, search, 'leads')
    return leads(rootState.user.currentClient.id, urlParams)
      .then(response => {
        commit(SET_LEADS, response.data.results)
        commit(SET_LEADS_MOBILE, { leads: response.data.results, reset: resetLeads })
        delete response.data.results
        commit(SET_LEADS_PAGINATION, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getLead: ({ commit, rootState }, { locationId, leadId }) => {
    return Promise.all([
      lead(rootState.user.currentClient.id, locationId, leadId),
      leadHistory(rootState.user.currentClient.id, locationId, leadId),
    ])
      .then(([lead, leadHistory]) => {
        commit(SET_CURRENT_LEAD, lead.data)
        commit(SET_CURRENT_LEAD_HISTORY, leadHistory.data.results)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },

  getGraph: ({ commit, rootState }, { view, dateRange }) => {
    return graph(rootState.user.currentClient.id, view, dateRange)
      .then(response => {
        commit(SET_DASHBOARD_GRAPH, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },

  getSummary: ({ commit, rootState }, { dateRange }) => {
    return summary(rootState.user.currentClient.id, dateRange)
      .then(response => {
        commit(SET_DASHBOARD_SUMMARY, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },

  getOverview: ({ commit, rootState }, { dateRange, goal }) => {
    return overview(rootState.user.currentClient.id, dateRange, goal)
      .then(response => {
        commit(SET_DASHBOARD_OVERVIEW, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },

  getNetwork: ({ commit, rootState }, { dateRange }) => {
    return network(rootState.user.currentClient.id, dateRange)
      .then(response => {
        commit(SET_DASHBOARD_NETWORK, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
